@value globals: "../GlobalStyles.css";
@value primaryBackgroundColor, textPrimaryColor, textSecondaryColor, textActiveColor from globals;
@value screen-sm, screen-md, screen-lg from globals;

.ActivityProgressSection.InfoBox {
  background-color: #472e93;
}

.ActivityProgressSection.InfoBox .boxHeader {
  background-color: #2f1973;
}

.ActivityProgressSection.InfoBox .boxContent {
  padding: 16px 20px;
  position: relative;
}

.ActivityProgressSection .ProgressSection .rankBadge {
  display: inline-block;
}

.ActivityProgressSection .ProgressSection .rankBadge img {
  cursor: pointer;
  height: 92px;
  width: 92px;
  transition: transform 0.3s ease;
  vertical-align: top;
}

.ActivityProgressSection .ProgressSection .numberCases {
  justify-content: left;
}

.ActivityProgressSection .ProgressSection .value {
  color: textActiveColor;
  display: inline-block;
  font-size: 61px;
  font-weight: 500;
  line-height: 92px;
  vertical-align: top;
}

.ActivityProgressSection .leaderboardRank {
  position: absolute;
  right: 16px;
  top: 19px;
}

.ActivityProgressSection .leaderboardRank .position {
  background-color: primaryBackgroundColor;
  border: solid 1px #9c7dfb;
  border-radius: 21px 3px;
  color: textActiveColor;
  font-size: 30px;
  font-weight: normal;
  height: 42px;
  line-height: 42px;
  margin-left: auto;
  text-align: center;
  width: 95px;
}

.ActivityProgressSection .leaderboardRank .description {
  color: textPrimaryColor;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38;
  margin-top: 10px;
  margin-left: auto;
  text-align: center;
  width: 95px;
}

@media (min-width: screen-sm) {
  .ActivityProgressSection.InfoBox .boxContent {
    padding: 10px 32px 16px;
  }

  .ActivityProgressSection .ProgressSection .rankBadge img {
    height: 130px;
    width: 130px;
  }

  .ActivityProgressSection .ProgressSection .value {
    font-size: 82px;
    line-height: 130px;
  }

  .ActivityProgressSection .plusPoints {
    font-size: 60px;
    top: -70px;
    left: 90px;
  }
}

@media (min-width: screen-md) {
  .ActivityProgressSection .ProgressSection .rankBadge img {
    height: 160px;
    width: 160px;
  }

  .ActivityProgressSection .ProgressSection .value {
    line-height: 160px;
  }
}

@media (min-width: screen-lg) {
  .ActivityProgressSection.InfoBox .boxContent {
    padding: 20px 32px;
  }

  .ActivityProgressSection .progressBarContainer {
    margin-bottom: 20px;
  }
}
