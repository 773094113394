@value headerHeight: 37px;
@value --gray-1: #a3a3a3;
@value --gray-2: #a0a0a0;
@value --gray-3: #c4c4c4;
@value --gray-4: #c0c0c0;
@value --gray-5: #9b9b9b;
@value --gray-6: #303030;

.select-tree-root {
  display: inline-block;
  text-align: initial;
  width: 320px;
  font-size: 14px;
  line-height: 26px;
}

.select-tree-root .tree-content {
  border: 1px solid --gray-3;
  border-radius: 5px;
  background-color: #ffffff;
}

.select-tree-root .tree-header {
  background-color: --gray-4;
  border-bottom: 1px solid --gray-3;
  color: --gray-6;
  padding: 5px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  justify-content: center;
  height: headerHeight;
}

.select-tree-root .tree-header .wrapperText {
  font-weight: bold;
}

.select-tree-root .wrapperText {
  white-space: nowrap;
  cursor: pointer;
}

.select-tree-root .breadcrumb .wrapperLabel {
  cursor: pointer;
}

.select-tree-root .breadcrumb .backIcon {
  padding-right: 10px;
}

.select-tree-root .breadcrumb .backIcon svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  color: #457fb0;
}

.select-tree-root .tree-options {
  overflow: auto;
}

.select-tree-root .tree-node,
.select-tree-root .tree-leaf {
  cursor: pointer;
  display: block;
  overflow: hidden;
  font-weight: normal;
  box-shadow: 0 0 0 200px transparent;
  line-height: 26px;
}

.select-tree-root .radioLabel .radioInput {
  display: none;
}
