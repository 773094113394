@value globals: "../GlobalStyles.css";
@value textPrimaryColor, textActiveColor from globals;

.Logo {
  cursor: pointer;
  display: inline-block;
}

.Logo .logoText {
  color: textPrimaryColor;
  font-weight: 600;
}

.Logo .logoText.highlight {
  color: textActiveColor;
}
