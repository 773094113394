@value globals: "../../GlobalStyles.css";
@value defaultColor, activeColor, hoverColor from globals;

.ToolbarButton {
  color: defaultColor;
  cursor: pointer;
  height: 48px;
  min-width: 48px;
  padding: 0 5px;
  text-align: center;
}

.ToolbarButton svg {
  fill: defaultColor;
  filter: drop-shadow(1px 1px 1px black);
  height: 30px;
  stroke: defaultColor;
  width: 30px;
}

.ToolbarButton:hover {
  color: hoverColor;
}

.ToolbarButton:hover svg {
  fill: hoverColor;
  stroke: hoverColor;
}

.ToolbarButton.active,
.ToolbarButton:active {
  color: activeColor;
}

.ToolbarButton.active svg,
.ToolbarButton:active svg {
  fill: activeColor;
  stroke: activeColor;
}

.ToolbarButton span {
  display: block;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
