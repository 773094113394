@keyframes pageEnter {
  0%, 49% {
    visibility: hidden;
  }
  50%, 100% {
    visibility: visible;
  }
}

.route-container {
  width: 100%;
  height: 100%;
}

.route-section {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
}

.route-section > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.route-section > .fade-enter {
  animation: pageEnter 2s linear;
}

.route-section > .fade-exit {
  z-index: 2;
}
