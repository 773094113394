@value globals: "../GlobalStyles.css";
@value primaryBackgroundColor, textPrimaryColor, uiBlack from globals;
@value screen-min, screen-xs, screen-sm, screen-md, screen-lg from globals;

@value boxContentBg: #1577b0;
@value boxHeaderBg: #00588a;
@value textColor: primaryBackgroundColor;

.AchievementSection.InfoBox {
  background-color: boxContentBg;
}

.AchievementSection.InfoBox .boxHeader {
  background-color: boxHeaderBg;
}

.AchievementSection .singleBadge {
  cursor: pointer;
  display: table;
  margin: 0 auto;
}

.AchievementSection .badgesGroup {
  cursor: pointer;
  margin: 0 auto 2px;
  width: 272px;
}

.AchievementSection .moreBadges {
  display: inline-block;
  color: textColor;
  font-size: 40px;
  font-weight: bold;
  line-height: 68px;
  padding-left: 3px;
  transition: color 0.3s ease;
}

.AchievementSection .viewAll {
  color: textColor;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

.AchievementSection .badgesSingle .viewAll {
  align-self: center;
}

.AchievementSection .badgesContent.badges-0 {
  height: 100%;
  min-height: 94px;
  padding-top: 0;
}

.AchievementSection .badgesContent.badges-0 .viewAll {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.AchievementSection .badgesContent.badges-0 .viewAll br {
  display: none;
}

@media (max-width: screen-min) {
  .AchievementSection .badgesSingle .viewAll {
    padding-top: 0;
  }
}

@media (min-width: screen-xs) {
  .AchievementSection .badgesContent {
    padding-top: 15px;
  }

  .AchievementSection .badgesGroup {
    margin-bottom: 0;
  }

  .AchievementSection .AchievementBadge {
    height: 92px;
    width: 92px;
  }

  .AchievementSection .moreBadges {
    line-height: 92px;
    padding-left: 15px;
  }

  .AchievementSection .viewAll {
    padding: 14px 0 10px;
  }
}

@media (min-width: screen-sm) {
  .AchievementSection.InfoBox .boxContent {
    height: 188px;
  }

  .AchievementSection .viewAll:hover,
  .AchievementSection .badgesGroup:hover .viewAll,
  .AchievementSection .badgesGroup:hover .moreBadges {
    color: textPrimaryColor;
  }

  .AchievementSection .AchievementBadge {
    transition: transform 0.3s ease;
  }

  .AchievementSection .AchievementBadge:hover {
    transform: scale(1.1);
  }

  .AchievementSection.InfoBox .boxContent {
    padding-bottom: 26px;
  }
}

@media (min-width: screen-md) {
  .AchievementSection.InfoBox .boxContent {
    height: 218px;
  }

  .AchievementSection .badgesContent {
    margin: 0 auto;
    max-width: 540px;
    padding-top: 35px;
  }

  .AchievementSection .badgesGroup {
    margin-left: 14px;
    margin-right: 10px;
  }

  .AchievementSection .viewAll {
    padding: 20px 0;
  }
}

@media (min-width: screen-lg) {
  .AchievementSection .badgesContent {
    max-width: 600px;
  }

  .AchievementSection .badgesGroup {
    margin-left: 30px;
    margin-right: 0;
  }

  .AchievementSection .viewAll {
    padding: 20px 0;
  }
}
