@value globals: "../GlobalStyles.css";
@value progressBackgroundColor, progressPrimaryColor, progressSecondaryColor from globals;
@value textPrimaryColor from globals;

@value progressValueFilter: blur(9.7px);
@value progressValueBackground: linear-gradient(to right, progressPrimaryColor, progressSecondaryColor);
@value progressValueResetBackground: linear-gradient(to right, progressPrimaryColor, white);

.ProgressBarWrapper {
  height: 12px;
  margin: 10px 0;
  position: relative;
  width: 100%;
}

.ProgressBarWrapper .startNumber,
.ProgressBarWrapper .endNumber {
  color: textPrimaryColor;
  font-size: 18px;
  font-weight: 600;
  height: 21px;
  position: absolute;
  top: -32px;
  width: 50%;
}

.ProgressBarWrapper .startNumber {
  left: 0;
  text-align: left;
}

.ProgressBarWrapper .endNumber {
  right: 0;
  text-align: right;
}

.ProgressBar {
  background-color: progressBackgroundColor;
  height: 100%;
  width: 100%;
}

.ProgressBar .ProgressValue {
  background-image: progressValueBackground;
  height: 100%;
  position: relative;
  transition: all 3s ease-in-out;
}

.ProgressBar .ProgressValue:before {
  background-image: progressValueBackground;
  content: '';
  filter: progressValueFilter;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ProgressBar.valueReset .ProgressValue {
  transition-duration: 0.2s;
  transition-delay: 1.8s;
}

.ProgressBar.valueReset {
  animation: progressBarReset 2s;
}

@keyframes progressBarReset {
  0% {
    filter: none;
    transform: scaleY(1);
  }
  25% {
    filter: none;
    transform: scaleY(1);
  }
  80% {
    filter: brightness(1.5);
    transform: scaleY(1.2);
  }
  100% {
    filter: none;
    transform: scaleY(1);
  }
}
