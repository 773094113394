@value backgroundColor: #1b2d63;
@value borderColor: #2271a0;
@value borderWidth: 4px;

.AchievementBadge {
  background-color: backgroundColor;
  border: borderWidth solid borderColor;
  border-radius: 50%;
  display: inline-block;
  height: 68px;
  overflow: hidden;
  width: 68px;
}

.AchievementBadge.inactive {
  filter: grayscale(1);
}

.AchievementBadge img {
  height: 100%;
  padding: 15%;
  vertical-align: top;
  width: 100%;
}
