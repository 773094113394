@value globals: "../GlobalStyles.css";
@value uiBlack from globals;

@value boxAnimation: 1.68s ease;

.Notification.notification-box {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.Notification.notification-box .background {
  background-color: #472e93;
  padding-top: 105px;
}

.Notification.notification-box .icon {
  background-color: #22193d;
  border-radius: 83px;
  box-shadow: 0 0 0 10px #472e93;
  height: 146px;
  left: 50%;
  padding: 20px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 146px;
}

.Notification.notification-box .icon img {
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.Notification.notification-box .halo {
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Notification.notification-box .halo1 {
  animation: notificationBoxIconHaloRotate 50s linear infinite;
  height: 196px;
  width: 196px;
}

.Notification.notification-box .halo2 {
  animation: notificationBoxIconHaloRotate 30s linear infinite;
  height: 228px;
  width: 228px;
}

.Notification.notification-box .halo3 {
  animation: notificationBoxIconHaloRotate 50s linear infinite;
  height: 276px;
  width: 276px;
}

.Notification.notification-box .title {
  background-color: uiBlack;
  border-radius: 28.5px;
  color: #00d5ae;
  display: inline-block;
  font-size: 23.8px;
  font-weight: 500;
  height: 50px;
  letter-spacing: 0.26px;
  line-height: 50px;
  margin: 25px 0 32px;
  text-align: center;
  width: 210px;
}

.Notification.notification-box .message {
  color: #ffffff;
  font-size: 37.8px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 44px;
  text-align: center;
}

/* Box animation */
@keyframes notificationBoxSlide {
  0% {
    transform: translateY(100%);
  }
  30%,
  100% {
    transform: translateY(0);
  }
}

.Notification.notification-box.fadingIn,
.Notification.notification-box.fadingOut {
  animation: notificationBoxSlide boxAnimation;
}

.Notification.notification-box.fadingOut {
  animation-direction: reverse;
}

/* Background animation */
@keyframes notificationBoxBackground {
  0% {
    background-color: transparent;
  }
  30%,
  100% {
    background-color: #472e93;
  }
}

.Notification.notification-box.fadingIn .background,
.Notification.notification-box.fadingOut .background {
  animation: notificationBoxBackground boxAnimation;
}

.Notification.notification-box.fadingOut .background {
  animation-direction: reverse;
}

/* Halo circles animation */
@keyframes notificationBoxIconHaloRotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes notificationBoxMessageHalo1 {
  0%,
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes notificationBoxMessageHalo2 {
  0%,
  59% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes notificationBoxMessageHalo3 {
  0%,
  62% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Notification.notification-box.fadingIn .halo1 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo1 boxAnimation;
}

.Notification.notification-box.fadingIn .halo2 {
  animation: notificationBoxIconHaloRotate 30s linear infinite,
    notificationBoxMessageHalo2 boxAnimation;
}

.Notification.notification-box.fadingIn .halo3 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo3 boxAnimation;
}

.Notification.notification-box.fadingOut .halo1 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo1 boxAnimation reverse;
}

.Notification.notification-box.fadingOut .halo2 {
  animation: notificationBoxIconHaloRotate 30s linear infinite,
    notificationBoxMessageHalo2 boxAnimation reverse;
}

.Notification.notification-box.fadingOut .halo3 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo3 boxAnimation reverse;
}

/* Icon animation */
@keyframes notificationBoxIcon {
  0% {
    box-shadow: 0 0 0 10px transparent;
    opacity: 0;
    transform: translate(-50%, 0);
  }
  12% {
    opacity: 1;
  }
  24% {
    box-shadow: 0 0 0 10px transparent;
  }
  36% {
    transform: translate(-50%, -50%);
  }
  48%,
  100% {
    box-shadow: 0 0 0 10px #472e93;
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.Notification.notification-box.fadingIn .icon,
.Notification.notification-box.fadingOut .icon {
  animation: notificationBoxIcon boxAnimation;
}

.Notification.notification-box.fadingOut .icon {
  animation-direction: reverse;
}

/* Title animation */
@keyframes notificationBoxTitle {
  0%,
  55% {
    color: transparent;
    opacity: 0;
    transform: scaleX(0);
  }
  65% {
    color: transparent;
  }
  74%,
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
  100% {
    color: #00d5ae;
  }
}

.Notification.notification-box.fadingIn .title,
.Notification.notification-box.fadingOut .title {
  animation: notificationBoxTitle boxAnimation;
}

.Notification.notification-box.fadingOut .title {
  animation-direction: reverse;
}

/* Message animation */
@keyframes notificationBoxMessage {
  0%,
  33% {
    opacity: 0;
    transform: translateY(150%);
  }
  57%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Notification.notification-box.fadingIn .message,
.Notification.notification-box.fadingOut .message {
  animation: notificationBoxMessage boxAnimation;
}

.Notification.notification-box.fadingOut .message {
  animation-direction: reverse;
}

/* Responsive layout */
@media (max-width: 1023px) {
  .Notification.notification-box .background {
    left: 50%;
    transform: scale(0.8) translateX(-12.5%);
    transform-origin: 50% 100%;
    width: 125%;
  }
}

@media (max-width: 639px) {
  .Notification.notification-box .background {
    left: 50%;
    transform: scale(0.5) translateX(-50%);
    transform-origin: 50% 100%;
    width: 200%;
  }
}
