@value globals: "./GlobalStyles.css";
@value primaryBackgroundColor, textPrimaryColor, textSecondaryColor, textActiveColor, screen-md, screen-lg from globals;
@value screen-xs, screen-sm, screen-md, screen-lg from globals;

.Dashboard {
  background-color: primaryBackgroundColor;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 15px;
}

.Dashboard .Logout {
  color: textPrimaryColor;
}

.Dashboard .rankingGroupSection {
  margin-top: 10px;
}

.Dashboard .hidden-version-text {
  color: transparent;
  cursor: default;
}

.DashboardTooltip {
  background-color: uiGrayDarker !important;
  color: textSecondaryColor !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.Dashboard .container {
  padding: 0 16px 0 11px;
}

@media (min-width: screen-xs) {
  .Dashboard .NotificationContainer {
    right: 30px;
  }
}

@media (min-width: screen-sm) {
  .Dashboard .NotificationContainer {
    right: 50px;
  }

  .Dashboard .container {
    padding: 0 35px;
  }
}

@media (min-width: screen-md) {
  .Dashboard .NotificationContainer {
    right: 75px;
  }

  .Dashboard .rankingGroupSection {
    margin-top: 65px;
  }

  .Dashboard .container {
    padding: 0 60px;
  }
}

@media (min-width: screen-lg) {
  .Dashboard .NotificationContainer {
    right: 95px;
  }

  .Dashboard .rankingGroupSection {
    margin-top: 0px;
  }

  .Dashboard .container {
    padding: 0 80px;
  }
}
