html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  box-sizing: border-box;
  font-family: 'Work Sans', 'SF Pro Text', 'Roboto', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.fixed-page {
  position: fixed;
  overflow: hidden;
}
