@value globals: "../GlobalStyles.css";
@value activeColor, uiBlack from globals;
@value screen-min, screen-xs, screen-xs-max, screen-sm from globals;

.MeasurementControl {
  display: table;
  position: relative;
}

.MeasurementControl .controls {
  border-radius: 8px;
  border: solid 1px #4a4d7a;
  background-color: uiBlack;
  padding: 10px 0;
  height: 45px;
}

.MeasurementControl .controls .number {
  color: #7cf4fe;
  font-size: 20px;
  line-height: 23px;
  margin: 0 10px;
}

.MeasurementControl .lesions {
  bottom: -20px;
  color: activeColor;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.MeasurementControl.disabled .label {
  display: none;
}

@media (min-width: screen-sm) {
  .MeasurementControl .label {
    background-color: #00d5ae;
    border: 0;
    border-radius: 9px;
    color: #100e44;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    height: 38px;
    line-height: 38px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -100%;
    text-align: center;
    top: 13px;
    transition: background-color 0.3s ease;
    width: 100px;
  }

  .MeasurementControl .label:hover {
    background-color: #ffffff;
  }

  .MeasurementControl .magnify {
    display: none;
  }

  .MeasurementControl .controls .left-arrow,
  .MeasurementControl .controls .right-arrow {
    border: 7px solid activeColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
    display: inline-block;
    width: 0;
    height: 0;
  }

  .MeasurementControl .controls .arrow-container {
    cursor: pointer;
    transition: 0.15s all ease;
  }

  .MeasurementControl .controls .arrow-container .arrow {
    transition: 0.15s all ease;
  }

  .MeasurementControl.disabled .arrow-container {
    background-color: #262548;
    cursor: not-allowed;
  }

  .MeasurementControl.disabled .arrow-container > .arrow {
    border: 7px solid #3a5874;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .MeasurementControl:not(.disabled) .arrow-container:hover {
    background-color: #0f373b;
  }

  .MeasurementControl:not(.disabled) .arrow-container:active {
    background-color: activeColor;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .arrow {
    border: 7px solid uiBlack;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .MeasurementControl.disabled .arrow-container > .left-arrow {
    border-left: 0px;
  }

  .MeasurementControl.disabled .arrow-container > .right-arrow {
    border-right: 0px;
  }

  .MeasurementControl .controls .right-arrow {
    border-right: 0px;
    margin-left: 2px;
  }

  .MeasurementControl .controls .left-arrow {
    border-left: 0px;
    margin-right: 2px;
  }

  .MeasurementControl .controls .arrow-container {
    background-color: #262548;
    border-radius: 50%;
    display: inline-block;
    height: 22px;
    margin: 0 10px;
    padding: 3px 0px 0px 6px;
    width: 22px;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .left-arrow {
    border-left: 0px;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .right-arrow {
    border-right: 0px;
  }
}

@media (max-width: screen-xs-max) {
  .MeasurementControl {
    display: flex;
    white-space: nowrap;
    width: 100%;
  }

  .MeasurementControl .controls,
  .MeasurementControl .label,
  .MeasurementControl .magnify {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid #16daac;
    border-radius: 9px;
    color: #ffffff;
    display: block;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 600;
    height: 38px;
    line-height: 38px;
    margin: 0 2%;
    overflow: hidden;
    padding: 0;
    text-align: center;
  }

  .MeasurementControl .label,
  .MeasurementControl .magnify {
    padding: 0 8px;
  }

  .MeasurementControl .magnify.active {
    color: activeColor;
  }

  .MeasurementControl .controls {
    display: flex;
  }

  .MeasurementControl .controls .number {
    color: #a8a8a8;
    flex-grow: 1;
    font-size: 25px;
    font-weight: normal;
    line-height: 38px;
    margin: 0;
    text-align: center;
    vertical-align: top;
  }

  .MeasurementControl .controls > span {
    background-color: transparent !important;
    display: inline-block;
    height: 100%;
    padding: 0 4px;
    position: relative;
    min-width: 38px;
  }

  .MeasurementControl .controls > span:not(:last-child) {
    border-right: 1px solid #16daac;
  }

  .MeasurementControl .controls .arrow-container {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .MeasurementControl .controls .arrow-container > .arrow {
    border: 2px solid #ffffff;
    border-left: 0;
    border-top: 0;
    display: block;
    height: 12px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform-origin: 50% 50%;
    width: 12px;
  }

  .MeasurementControl .controls .arrow-container > .arrow.left-arrow {
    transform: translate(calc(-50% + 3px), -50%) rotate(135deg);
  }

  .MeasurementControl .controls .arrow-container > .arrow.right-arrow {
    transform: translate(calc(-50% - 3px), -50%) rotate(-45deg);
  }

  .MeasurementControl.disabled {
    display: none;
  }
}
