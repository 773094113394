.CaseControlButtons {
  white-space: nowrap;
}

.CaseControlButtons .CompleteButtonWrapper {
  display: inline-block;
  vertical-align: top;
}

.CaseControlButtons button {
  background-color: #00d5ae;
  border: 0;
  border-radius: 9px;
  color: #100e44;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 43px;
  line-height: 43px;
  opacity: 1;
  outline: none;
  padding: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  vertical-align: middle;
  width: 145px;
}

.CaseControlButtons button.complete-case {
  border-radius: 0 9px 9px 0;
}

.CaseControlButtons button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.CaseControlButtons button:not(:disabled):hover {
  background-color: white;
}

.CaseControlButtons .btn-group {
  display: inline-block;
  margin-left: 15px;
}
