@value globals: "../GlobalStyles.css";
@value activeColor, uiBlack from globals;
@value screen-min from globals;

.AchievementDetails {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 9px;
  display: flex;
  margin: 10px 0;
  padding: 10px 20px;
  position: relative;
}

.AchievementDetails .AchievementBadge {
  border: 0;
  border-radius: 4px;
  min-width: 68px;
}

.AchievementDetails .info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
}

.AchievementDetails .info .title {
  color: #ffffff;
  display: none; /* TODO: [layout] put back when each badge has unique name */
  font-size: 18px;
  font-weight: 600;
}

.AchievementDetails .info .description {
  font-size: 15px;
}

.AchievementDetails .info .progress {
  background-color: #28008c;
  border: 1px solid #40297b;
  border-radius: 8px;
  display: none; /* TODO: [layout] put back when each badge has progress */
  height: 16px;
  margin-top: 5px;
  overflow: hidden;
}

.AchievementDetails .info .current {
  background-color: activeColor;
  font-size: 15px;
  height: 100%;
  line-height: 14px;
  position: relative;
}

.AchievementDetails .info .current:not(.after) {
  color: uiBlack;
  text-align: center;
}

.AchievementDetails .info .current.after {
  color: #ffffff;
}

.AchievementDetails .info .current.after .value {
  display: table;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(calc(100% + 10px));
}

.AchievementDetails .date {
  display: none; /* TODO: [layout] put back when storing badge earned date */
  font-size: 15px;
  position: absolute;
  right: 20px;
  top: 8px;
}

.AchievementDetails.active .info .progress {
  display: none;
}

.AchievementDetails.inactive .date {
  display: none;
}

@media (max-width: screen-min) {
  .AchievementDetails {
    padding: 10px;
  }

  .AchievementDetails .info {
    padding-left: 10px;
  }

  .AchievementDetails .date {
    display: none;
  }
}
