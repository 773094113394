.labellingComponent {
  position: absolute;
  text-align: center;
  z-index: 300;
}

.labellingComponent .selectedLabel,
.labellingComponent .selectedDescription {
  padding: 5px;
  background-color: white;
  width: 150px;
}

.labellingComponent .addLabelButton {
  color: #000000;
  background-color: #20a5d6;
  border: 2px solid #44626f;
  border-radius: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  opacity: 1;
  padding: 0 14px;
  transition: opacity 0.3s ease;
  outline: none;
  cursor: pointer;
}

.labellingComponent .commonButton {
  border: 1px solid #44626f;
  color: #ffffff;
  background-color: #000000;
  border-radius: 16px;
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  padding: 0 12px;
  margin: 10px 5px 0 0;
  outline: none;
  cursor: pointer;
}

.labellingComponent .textArea {
  background-color: #ffffff;
  line-height: 46px;
  max-width: 320px;
  min-width: 260px;
  padding: 0 12px;
  margin: 0 auto;
  display: inline-block;
}

.labellingComponent .checkIconWrapper {
  display: inline-block;
  background-color: #79f9fe;
  border-radius: 46px;
  width: 46px;
  height: 46px;
  margin-right: 10px;
  vertical-align: bottom;
  cursor: pointer;
}

.labellingComponent .checkIcon {
  width: 20px;
  height: 20px;
  margin: 13px;
  fill: black;
}

.labellingComponent .commonButtons {
  text-align: center;
  margin-left: 46px;
}

.labelling-appear {
  opacity: 0;
}
.labelling-appear.labelling-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.labelling-exit {
  opacity: 1;
}
.labelling-exit.labelling-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.labelling-exit-done {
  opacity: 0;
}
