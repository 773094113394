.ViewportOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.ViewportOverlay .overlay-element {
  position: absolute;
  color: lightblue;
  text-shadow: 1px 1px black;
}

.ViewportOverlay .overlay-element > span {
  display: block;
}

.ViewportOverlay .top-right {
  top: 10px;
  right: 25px; /* room for scrollbar */
}

.ViewportOverlay .top-left {
  top: 10px;
  left: 10px;
}

.ViewportOverlay .bottom-right {
  bottom: 10px;
  right: 30px;
}

.ViewportOverlay .bottom-left {
  bottom: 10px;
  left: 10px;
}
