@value globals: "../GlobalStyles.css";
@value screen-min, screen-xs-max, screen-sm-max from globals;

.NotificationContainer {
  position: fixed;
  right: 400px;
  top: 45px;
  width: 330px;
  z-index: 150;
}

@media (max-width: screen-sm-max) {
  .NotificationContainer {
    top: 16px;
  }
}

@media (max-width: screen-xs-max) {
  .NotificationContainer {
    right: 40px;
    top: 80px;
  }
}

@media (max-width: screen-min) {
  .NotificationContainer {
    right: 5px;
    top: 4px;
    width: calc(100% - 10px);
  }

  .NotificationContainer .NotificationToast {
    padding: 5px;
  }
}
