@value globals: "./GlobalStyles.css";
@value activeColor, screen-min, screen-sm-max from globals;
@value screen-md, screen-md-max, screen-lg from globals;

.Leaderboard {
  overflow-y: scroll;
  padding: 5px 24px 50px;
}

.Leaderboard .Logo {
  font-size: 32px;
  margin: 20px 16px 0;
  white-space: nowrap;
}

.Leaderboard .section {
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
}

.Leaderboard .list {
  margin-top: 20px;
}

.Leaderboard .section h2 {
  color: activeColor;
  display: flex;
  font-size: 36px;
  font-weight: normal;
  line-height: 50px;
  margin: 0;
  white-space: nowrap;
}

.Leaderboard .section h2 img {
  margin: 0 16px;
}

.Leaderboard .sectionIndividuals .LeaderboardItem {
  max-height: 66px;
  min-height: 66px;
}

.Leaderboard .sectionTeams .LeaderboardItem {
  max-height: 56px;
  min-height: 56px;
}

.Leaderboard .sectionIndividuals .LeaderboardItem:nth-child(n+21) {
  display: none;
}

.Leaderboard .sectionTeams .LeaderboardItem:nth-child(n+7) {
  display: none;
}

@media (min-width: screen-md) {
  .Leaderboard .sectionIndividuals .list {
    height: 660px;
  }

  .Leaderboard .sectionTeams .list {
    height: 168px;
  }

  .Leaderboard .list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
  }
}

@media (min-width: screen-lg) {
  .Leaderboard .LeaderboardItem .name > span:first-child {
    flex-grow: 1;
  }
}

@media (max-width: screen-md-max) {
  .Leaderboard .LeaderboardItem .name {
    flex-direction: column;
    justify-content: center;
  }

  .Leaderboard .LeaderboardItem .name > span {
    width: 100%;
  }

  .Leaderboard .LeaderboardItem .name .team {
    background-color: transparent;
    height: auto;
  }

  .Leaderboard .LeaderboardItem .name .team span {
    display: block;
    opacity: 0.5;
    padding: 0;
    white-space: nowrap;
  }
}

@media (max-width: screen-sm-max) {
  .Leaderboard .LeaderboardItem {
    width: calc(100% - 32px);
  }
}

@media (max-width: screen-min) {
  .Leaderboard {
    padding: 5px 12px 20px;
  }

  .Leaderboard .Logo {
    font-size: 26px;
    margin: 10px 0 0;
  }

  .Leaderboard .section {
    margin-top: 10px;
    padding-top: 10px;
  }

  .Leaderboard .section h2 {
    border-bottom: 1px solid activeColor;
    font-size: 24px;
  }

  .Leaderboard .section h2 img {
    margin: 0 10px;
    margin-right: 10px;
    max-width: 32px;
  }

  .Leaderboard .section .LeaderboardItem {
    border-bottom: 0;
    margin: 5px 0;
    max-height: 66px;
    min-height: 66px;
  }

  .Leaderboard .list {
    margin-top: 0;
  }

  .Leaderboard .LeaderboardItem {
    margin: 0;
    position: relative;
    width: 100%;
  }

  .Leaderboard .LeaderboardItem .rank {
    align-items: baseline;
    font-size: 20px;
    height: 56px;
    padding-top: 4px;
    width: 70px;
  }

  .Leaderboard .LeaderboardItem .rank::before {
    content: '#';
  }

  .Leaderboard .LeaderboardItem .score {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    bottom: 8px;
    box-shadow: inset 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    font-size: 18px;
    left: 4px;
    margin: 0;
    min-width: unset;
    position: absolute;
    text-align: center;
    width: 62px;
  }

  .Leaderboard .LeaderboardItem .name > span:first-child {
    flex-grow: 0;
    font-size: 18px;
  }

  .Leaderboard .LeaderboardItem .name .team span {
    font-size: 16px;

    display: -webkit-box;
    padding: 0;
    white-space: unset;
  }
}
