@value globals: "../../GlobalStyles.css";
@value uiBlack from globals;
@value screen-min, screen-xs, screen-xs-max, screen-sm, screen-sm-max from globals;
@value screen-md, defaultColor from globals;

.ToolbarSection {
  border-radius: 8px;
  display: flex;
  margin-top: 17px;
  z-index: 100;
}

.ToolbarSection .wlPresets,
.ToolbarSection .tools {
  display: flex;
}

.ToolbarSection .presetSelected {
  pointer-events: none;
}

@media (min-width: screen-md) {
  .ToolbarSection {
    flex-direction: row-reverse;
  }

  .ToolbarSection .presetSelected {
    display: none;
  }
}

@media (max-width: screen-sm-max) {
  .ToolbarSection .presetSelected {
    bottom: -22px;
    color: defaultColor;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    white-space: nowrap;
  }

  .ToolbarSection .tools svg,
  .ToolbarSection .wlPresets svg {
    height: 20px;
    width: 20px;
  }

  .ToolbarSection .ToolbarButton {
    display: inline-block;
    height: auto;
    min-width: auto;
    padding: 8px 7px;
  }

  .ToolbarSection .tools .caption,
  .ToolbarSection .wlPresets .caption {
    display: none;
  }
}

@media (min-width: screen-sm) and (max-width: screen-sm-max) {
  .ToolbarSection .wlPresets {
    border-radius: 8px;
    border: solid 1px #4a4d7a;
    display: table;
    padding: 0 4px;
    position: relative;
    white-space: nowrap;
  }
}

@media (max-width: screen-xs-max) {
  .ToolbarSection {
    margin-top: 0;
    width: 100%;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    border-right: 2px solid black;
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
  }

  .ToolbarSection .ToolbarButton {
    align-items: center;
    min-width: 34px;
    min-height: 34px;
    padding: 0;
    position: relative;
    width: auto;
  }

  .ToolbarSection .ToolbarButton svg {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .ToolbarSection .presetSelected {
    left: 8px;
    bottom: -24px;
    text-shadow: 1px 1px uiBlack;
  }

  .ToolbarSection .tool-Pan,
  .ToolbarSection .tool-Zoom,
  .ToolbarSection .tool-StackScroll,
  .ToolbarSection .tool-reset {
    display: none !important;
  }
}

@media (min-width: screen-xs) and (max-width: screen-xs-max) {
  .ToolbarSection .ToolbarButton {
    display: flex;
    flex-grow: 1;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    flex-grow: 1;
  }

  .ToolbarSection .wlPresets {
    flex-basis: 66%;
  }

  .ToolbarSection .tools {
    flex-basis: 34%;
  }

  .ToolbarSection .wlPresets .ToolbarButton {
    min-width: 25%;
  }

  .ToolbarSection .tools .ToolbarButton {
    min-width: 50%;
  }

  .ToolbarSection .ToolbarButton svg {
    max-height: 30px;
    max-width: 30px;
    min-height: 20px;
    min-width: 20px;
    width: calc(100% - 16px);
  }
}

@media (max-width: screen-min) {
  .ToolbarSection {
    display: flex;
    padding-top: 5px;
    width: 100%;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    border: 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    padding: 0;
    white-space: nowrap;
  }

  .ToolbarSection .ToolbarButton {
    height: 40px;
    max-width: 40px;
    min-width: 36px;
    width: auto;
  }

  .ToolbarSection .ToolbarButton svg {
    width: calc(100% - 12px);
  }
}
