.Notification.notification-toast {
  align-items: center;
  background-color: #22193d;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}

.Notification.notification-toast .icon {
  display: inline-block;
  height: 50px;
  margin-right: 15px;
  min-width: 50px;
  vertical-align: middle;
  width: 50px;
}

.Notification.notification-toast .icon img {
  width: 50px;
  height: 50px;
}

/* TODO: [layout] Change for SVG icon when available */
.Notification.notification-toast .icon .default {
  background-color: #410988;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 25px;
  font-size: 36px;
  font-weight: 100;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.Notification.notification-toast .notificationText {
  font-size: 16px;
}

.Notification.notification-toast .notificationText .title {
  color: #ffffff;
  line-height: 1.31;
  margin: 0;
}

.Notification.notification-toast .notificationText .message {
  color: #aca2cd;
}

/* Toast fadeIn animation */
@keyframes notificationToastFadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-10%);
  }
  80% {
    transform: translateY(2%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Notification.fadingIn.notification-toast {
  animation: notificationToastFadeIn 0.3s ease;
}

/* Toast fadeOut animation */
@keyframes notificationToastFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.Notification.fadingOut.notification-toast {
  animation: notificationToastFadeOut 0.3s ease;
}
