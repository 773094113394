@value globals: "../GlobalStyles.css";
@value activeColor, textActiveColor, textSecondaryColor from globals;
@value hoverColor, uiBlack, screen-sm from globals;

@value infoIconFillColor: #034045;
@value imageContainerColor: uiBlack;
@value infoContainerColor: #090020;
@value dividerColor: #24225b;
@value progressBorderColor: #40297b;
@value progressBackgroundColor: #28008c;
@value progressForegroundColor: activeColor;

.CaseTypeCard {
  background-color: imageContainerColor;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
}

.CaseTypeCard .imageContainer {
  border-bottom: 2px solid dividerColor;
  height: 268px;
  padding: 22px 0;
  text-align: center;
  overflow: hidden;
}

.CaseTypeCard .imageContainer img {
  animation: fadeIn 1s;
  height: 100%;
  transition: transform 0.3s ease;
}

.CaseTypeCard .infoIcon {
  fill: infoIconFillColor;
  height: 20px;
  opacity: 0.41;
  position: absolute;
  right: 10px;
  stroke: textActiveColor;
  top: 10px;
  transition: opacity 0.3s ease;
  width: 20px;
  z-index: 2;
}

.CaseTypeCard .infoIcon:hover {
  opacity: 1;
}

.CaseTypeCard .infoIcon svg {
  height: 20px;
  width: 20px;
}

.CaseTypeCard .infoContainer {
  background-color: infoContainerColor;
  height: 112px;
  padding: 16px;
}

.CaseTypeCard .infoContainer .progress {
  background-color: progressBackgroundColor;
  border: 1px solid progressBorderColor;
  border-radius: 5px;
  height: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 75px;
}

.CaseTypeCard .infoContainer .progress .current {
  animation: slideRightIn 3s;
  background-color: activeColor;
  border-radius: 4px;
  display: table;
  height: 100%;
  min-width: 8px;
}

.CaseTypeCard .infoContainer .typeName {
  animation: fadeIn 1s;
  color: textActiveColor;
  font-size: 19px;
  font-weight: normal;
  line-height: 1.45;
  transition: color 0.3s ease;
}

.CaseTypeCard .imageContainer .spinner {
  display: none;
}

.CaseTypeCard.placeholder {
  cursor: default;
}

.CaseTypeCard.placeholder .infoIcon {
  display: none;
}

.CaseTypeCard.placeholder .infoContainer .progress .current {
  display: none;
}

.CaseTypeCard.placeholder:hover:after {
  border-color: transparent;
}

.CaseTypeCard.placeholder .imageContainer img {
  display: none;
}

.CaseTypeCard.placeholder .imageContainer .spinner {
  display: block;
  height: 100%;
}

.CaseTypeCard.placeholder .imageContainer .spinner svg {
  animation: rotate 1s linear infinite;
  display: block;
  fill: hoverColor;
  height: 100%;
  margin: 0 auto;
  opacity: 0.5;
  width: 80px;
}

@media (min-width: screen-sm) {
  .CaseTypeCard {
    margin-bottom: 22px;
  }

  .CaseTypeCard:hover .imageContainer img {
    transform: scale(1.1);
  }

  .CaseTypeCard:after {
    border: 4px solid transparent;
    border-radius: 9px;
    bottom: -10px;
    content: '';
    left: -10px;
    pointer-events: none;
    position: absolute;
    right: -10px;
    top: -10px;
    transition: border-color 0.3s ease;
  }

  .CaseTypeCard:hover:after {
    border-color: activeColor;
  }

  .CaseTypeCard:hover .infoContainer .typeName {
    color: hoverColor;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideRightIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
