@value globals: "../GlobalStyles.css";
@value textActiveColor, hoverColor, uiUsernameText, dashboardInfo-lg from globals;
@value screen-min, screen-xs, screen-sm, screen-md, screen-lg from globals;

@value iconSize: 36px;
@value iconSize-xs: 36px;
@value iconSize-sm: 42px;
@value iconSize-md: 60px;
@value iconSize-lg: 60px;

@value headerPadding: 15px 12px;
@value headerPadding-xs: 10px 12px;
@value headerPadding-sm: 10px 35px;
@value headerPadding-md: 16px 60px;
@value headerPadding-lg: 30px 80px;

@value headerHeight: 158px;
@value headerHeight-xs: 94px;
@value headerHeight-sm: 94px;
@value headerHeight-md: 130px;
@value headerHeight-lg: 140px;

.simpleHeader {
  display: flex;
  flex-wrap: wrap;
  padding: headerPadding;
}

.simpleHeader .userIcon {
  display: inline-block;
}

.simpleHeader .userIcon img {
  width: 100%;
  height: 100%;
}

.simpleHeader .userSection .username {
  color: uiUsernameText;
  font-size: 17px;
  font-weight: normal;
  padding-left: 10px;
}

.simpleHeader .userSection > * {
  vertical-align: top;
}

.simpleHeader .actionSection > * {
  color: textActiveColor;
  cursor: pointer;
  font-family: 'SF Pro Text', 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-left: 42px;
  text-decoration: none;
}

.simpleHeader .actionSection > *:first-child {
  margin-left: 0 !important;
}

.simpleHeader {
  height: headerHeight;
  padding: headerPadding;
}

.simpleHeader .Logo {
  font-size: 20px;
  line-height: iconSize;
  white-space: nowrap;
}

.simpleHeader .userSection {
  flex-grow: 1;
  white-space: nowrap;
}

.simpleHeader .actionSection {
  white-space: nowrap;
}

.simpleHeader .userIcon {
  border-radius: calc(iconSize / 2);
  height: iconSize;
  width: iconSize;
}

@media (min-width: screen-xs) {
  .simpleHeader {
    height: headerHeight-xs;
    padding: headerPadding-xs;
  }

  .simpleHeader .Logo {
    display: block;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    width: 100%;
  }

  .simpleHeader .userSection .username {
    max-width: 210px;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    display: inline-block;
    line-height: iconSize-xs;
  }

  .simpleHeader .userIcon {
    border-radius: calc(iconSize-xs / 2);
    height: iconSize-xs;
    width: iconSize-xs;
  }

  .simpleHeader .actionSection {
    padding-right: 5px;
  }

  .simpleHeader .actionSection > * {
    margin-left: 20px;
  }
}

@media (min-width: screen-sm) {
  .simpleHeader {
    align-content: stretch;
    height: headerHeight-sm;
    padding: headerPadding-sm;
  }

  .simpleHeader .userSection .username {
    max-width: unset;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    line-height: iconSize-sm;
  }

  .simpleHeader .actionSection > * {
    transition: color 0.3s ease;
  }

  .simpleHeader .actionSection > *:hover {
    color: hoverColor;
  }

  .simpleHeader .userSection .userIcon {
    border-radius: calc(iconSize-sm / 2);
    height: iconSize-sm;
    width: iconSize-sm;
  }

  .simpleHeader .userSection .username {
    font-size: 24px;
    padding-left: 12px;
  }

  .simpleHeader .actionSection > * {
    margin-left: 28px;
  }
}

@media (min-width: screen-md) {
  .simpleHeader {
    height: headerHeight-md;
    padding: headerPadding-md;
  }

  .simpleHeader .Logo {
    font-size: 32px;
    line-height: 38px;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    line-height: iconSize-md;
  }

  .simpleHeader .userSection .userIcon {
    border-radius: calc(iconSize-md / 2);
    height: iconSize-md;
    width: iconSize-md;
  }

  .simpleHeader .userSection .username {
    font-size: 26px;
    padding-left: 20px;
  }
}

@media (min-width: screen-lg) {
  .simpleHeader {
    flex-wrap: nowrap;
    height: headerHeight-lg;
    padding: headerPadding-lg;
    position: relative;
  }

  .simpleHeader .Logo {
    line-height: iconSize-md;
    text-align: left;
    width: calc(62.8% + 10px);
  }

  .simpleHeader .actionSection {
    padding: 0;
    position: absolute;
    left: calc(62.8% + 71px);
    top: 90px;
  }

  .simpleHeader .actionSection > * {
    height: 32px;
    line-height: 32px;
    margin-left: 0;
    margin-right: 28px;
  }
}

@media (max-width: screen-min) {
  .simpleHeader .Logo,
  .simpleHeader .userSection,
  .simpleHeader .actionSection {
    flex-basis: 100%;
  }

  .simpleHeader .userSection {
    margin: 15px 0;
  }

  .simpleHeader .userSection .username {
    line-height: iconSize;
    padding-left: 6px;
  }

  .simpleHeader .actionSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding-right: 5px;
    max-width: 360px;
  }

  .simpleHeader .actionSection > * {
    margin: 0;
  }
}
