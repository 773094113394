@value globals: "./GlobalStyles.css";
@value primaryBackgroundColor, textPrimaryColor, textActiveColor, uiBlack from globals;
@value screen-min, screen-xs-max, screen-sm-max, screen-md-max from globals;

.SessionSummary {
  background-color: primaryBackgroundColor;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 15px;
  position: relative;
}

.SessionSummary .Logo {
  left: 27px;
  position: absolute;
  top: 19px;
}

.SessionSummary .pageHeader {
  display: flex;
  height: 46px;
  margin: 127px 0 40px;
  padding-left: 25px;
}

.SessionSummary .pageHeader h1 {
  color: textActiveColor;
  flex-grow: 1;
  font-size: 34px;
  font-style: italic;
  font-weight: 500;
  line-height: 46px;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.SessionSummary .controlButtons {
  white-space: nowrap;
}

.SessionSummary .controlButtons button {
  border: 0;
  border-radius: 9px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 46px;
  line-height: 46px;
  margin-left: 21px;
  margin-right: 8px;
  padding: 0;
  text-align: center;
}

.SessionSummary .controlButtons .logout {
  background-color: #2a0e6f;
  border: 1px solid #ffffff;
  color: #ffffff;
  line-height: 44px;
  width: 85px;
}

.SessionSummary .controlButtons .dashboard {
  background-color: #00d5ae;
  color: #100e44;
  width: 181px;
}

.SessionSummary .cardSection {
  background-color: #472e93;
  border-radius: 9px;
  box-shadow: 13px 13px 0 0 #030628;
  margin-top: 20px;
  padding: 17px 23px;
}

.SessionSummary .cardSection h2 {
  color: #ffffff;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  line-height: 34px;
  margin: 0;
  text-transform: uppercase;
}

.SessionSummary .sessionTotalValue {
  color: textActiveColor;
  font-size: 102px;
  font-weight: 600;
  margin-top: -38px;
  text-align: center;
}

.SessionSummary .earnedBadgesSection {
  margin-top: 29px;
}

.SessionSummary .earnedBadgesSection .badge {
  background-color: rgb(59, 59, 189, 0.25);
  border-radius: 9px;
  height: 214px;
  margin-bottom: 25px;
  padding: 24px 10px;
  text-align: center;
}

.SessionSummary .earnedBadgesSection .AchievementBadge {
  background-color: transparent;
  border: 0;
  height: 100px;
  width: 100px;
}

.SessionSummary .earnedBadgesSection .badgeEarned,
.SessionSummary .earnedBadgesSection .badgeDescription {
  font-size: 20px;
  line-height: 24px;
  margin-top: 6px;
}

.SessionSummary .earnedBadgesSection .badgeEarned {
  color: #ffffff;
  font-weight: 600;
}

.SessionSummary .earnedBadgesSection .badgeDescription {
  color: #aca2cd;
  font-weight: normal;
}

.SessionSummary .earnedBadgesSection .badgeProgress {
  background-color: uiBlack;
  border-radius: 6.5px;
  display: inline-block;
  height: 11px;
  margin: 8px 0 7px;
  width: 78px;
}

.SessionSummary .earnedBadgesSection .badgeProgressValue {
  background-color: #00d5ae;
  border-radius: 6.5px;
  height: 100%;
}

.SessionSummary .LogoutSection {
  margin-top: 100px;
}

.SessionSummary .ProgressSection .numberCases {
  margin: -20px 0 30px;
}

.SessionSummary .ProgressSection .progressBarContainer {
  margin: 10px 42px 24px;
}

@media (max-width: screen-sm-max) {
  .SessionSummary .pageHeader {
    margin-top: 100px;
    padding-left: 10px;
  }
}

@media (max-width: screen-xs-max) {
  .SessionSummary .pageHeader {
    margin-bottom: 32px;
    margin-top: 60px;
    padding-left: 0;
  }

  .SessionSummary .sessionTotalValue {
    font-size: 80px;
    margin-top: 0;
  }

  .SessionSummary .ProgressSection .numberCases {
    margin-top: 10px;
  }

  .SessionSummary .ProgressSection .progressBarContainer {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: screen-min) {
  .SessionSummary .ProgressSection .numberCases {
    margin-top: 20px;
  }

  .SessionSummary .ProgressSection .value {
    font-size: 60px;
    line-height: 128px;
  }

  .SessionSummary .pageHeader {
    height: 70px;
  }

  .SessionSummary .pageHeader h1 {
    font-size: 28px;
    line-height: 70px;
  }

  .SessionSummary .controlButtons {
    text-align: right;
    width: 110px;
    white-space: normal;
  }

  .SessionSummary .controlButtons button {
    height: 32px;
    line-height: 32px;
    margin: 0;
  }

  .SessionSummary .controlButtons button.dashboard {
    margin-top: 10px;
    width: 110px;
  }

  .SessionSummary .controlButtons button.logout {
    line-height: 30px;
    margin-right: 8px;
    width: 110px;
  }
}
