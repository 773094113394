@value globals: "../GlobalStyles.css";
@value screen-sm, screen-lg, screen-xl from globals;

@value textColor: #f2e0f7;

.StatisticsCard {
  box-sizing: border-box;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 24px;
  padding: 0 20px;
  white-space: nowrap;
}

.StatisticsCard .lineGroup {
  margin: 25px 0 15px;
}

.StatisticsCard .number {
  color: textColor;
  display: inline-block;
  font-weight: normal;
  font-size: 50px;
  line-height: 50px;
  position: relative;
  vertical-align: top;
}

.StatisticsCard .number .maxWidth {
  opacity: 0;
}

.StatisticsCard .number .visible {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.StatisticsCard .icon {
  display: inline-block;
  height: 50px;
  margin-right: 5px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 48px;
}

.StatisticsCard .icon img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.StatisticsCard .description {
  display: block;
  font-weight: normal;
  font-size: 16px;
  color: textColor;
  line-height: 16px;
  white-space: nowrap;
}

@media (min-width: screen-sm) {
  .StatisticsCard {
    padding: 0 30px;
  }

  .StatisticsCard .lineGroup {
    margin-top: 32px;
  }
}

@media (min-width: screen-lg) {
  .StatisticsCard {
    padding: 0 15px;
  }
}

@media (min-width: screen-xl) {
  .StatisticsCard {
    padding: 0 30px;
  }
}
