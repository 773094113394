@value globals: "../GlobalStyles.css";
@value textPrimaryColor, textSecondaryColor, textActiveColor from globals;
@value screen-min, screen-xs-max, screen-sm from globals;

.Modal .rankBadge {
  margin: 20px 0;
}

.Modal .rankBadge .badgeName,
.Modal .rankBadge .badgeRequirement {
  margin: 0;
  text-align: center;
}

.Modal .rankBadge .badgeName {
  color: #ffffff;
}

.ProgressSection .title {
  font-weight: bold;
  font-size: 18px;
  color: textPrimaryColor;
}

.ProgressSection .numberCases {
  display: flex;
  justify-content: center;
}

.ProgressSection .currentPoints {
  margin-left: 10px;
  position: relative;
}

.ProgressSection .value {
  display: inline-block;
  color: textPrimaryColor;
  font-weight: 600;
  font-size: 120px;
  line-height: 184px;
  position: relative;
  top: 0px;
}

.ProgressSection .value .maxWidth {
  visibility: hidden;
}

.ProgressSection .value .visible {
  left: 0;
  position: absolute;
  top: 0;
}

.ProgressSection .plusPoints {
  transition: all 0.3s linear;
  color: textActiveColor;
  position: absolute;
  font-size: 33px;
  font-weight: 600;
  top: -35px;
  left: 55px;
  opacity: 0;
}

.ProgressSection .rankBadge img {
  height: 184px;
  width: 184px;
}

@media (min-width: screen-sm) {
  .ProgressSection .rankBadge {
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .ProgressSection .rankBadge:hover {
    transform: scale(1.1);
  }
}

@media (max-width: screen-xs-max) {
  .ProgressSection .rankBadge {
    cursor: auto;
  }
}

@media (max-width: screen-min) {
  .ProgressSection .rankBadge img {
    height: 128px;
    width: 128px;
  }

  .ProgressSection .currentPoints {
    margin-left: 0;
  }
}
