/* State palette */
@value uiStateError: #FFCCCC;
@value uiStateErrorBorder: #993333;
@value uiStateErrorText: #661111;

@value uiUsernameText: #a592d2;

@value uiBlack: #000000;
@value uiGrayLight: #516873;
@value uiGray: #263340;
@value uiGrayDark: #16202B;
@value uiGrayDarker: #151A1F;
@value uiGrayDarkest: #14202A;

/* Interface UI Colors */
@value defaultColor: #9CCEF9;
@value hoverColor: #ffffff;
@value activeColor: #7cf4fe;
@value secondaryColor: #b994fe;
@value uiBorderColor: #44626F;
@value uiBorderColorDark: #3C5D80;
@value uiBorderColorActive: #79f9fe;
@value primaryBackgroundColor: #140046;
@value boxBackgroundColor: #3E5975;
@value rankBadgeBorderColor: #605886;
@value viewerHeaderColor: #262548;

/* Text Colors */
@value textActiveColor: activeColor;
@value textPrimaryColor: #ffffff;
@value textSecondaryColor: #b8baee;
@value largeNumbersColor: #6FBDE2;
@value textDisabledColor: #878787;

/* Other Colors */
@value progressBackgroundColor: primaryBackgroundColor;
@value progressPrimaryColor: activeColor;
@value progressSecondaryColor: secondaryColor;
@value imageSliderColor: #472e93;

/* Screen resolutions */
@value 640w: 640px;
@value 1024w: 1024px;
@value 1366w: 1366px;
@value 1680w: 1680px;
@value 1920w: 1920px;
@value 2048w: 2048px;
@value 2280w: 2280px;
@value 2560w: 2560px;
@value 2880w: 2880px;
@value 3200w: 3200px;
@value 3440w: 3440px;
@value 3840w: 3840px;
@value 4096w: 4096px;
@value 4500w: 4500px;
@value 5120w: 5120px;
@value 6400w: 6400px;
@value 7680w: 7680px;
@value 8192w: 8192px;
@value 10240w: 10240px;
@value 15360w: 15360px;

/* Grid breakpoints */
@value screen-min: 639px;
@value screen-xs: 640px;
@value screen-xs-max: 1023px;
@value screen-sm: 1024px;
@value screen-sm-max: 1365px;
@value screen-md: 1366px;
@value screen-md-max: 1679px;
@value screen-lg: 1680px;
@value screen-lg-max: 1919px;
@value screen-xl: 1920px;
@value gutter: 12.5px;
@value negativeGutter: -12.5px;

body {
  background-color: primaryBackgroundColor;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.Dashboard > .layoutGroup {
  margin: 0 auto;
  max-width: screen-xl;
  transform-origin: 50% 0%;
}

.rankBadge.not-received img {
  opacity: 0.25;
}

.link {
  border: 0;
  background-color: transparent;
  padding: 0;
}

@media (min-width: 2048w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(2048 / 1920));
  }
}
@media (min-width: 2280w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(2280 / 1920));
  }
}
@media (min-width: 2560w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(2560 / 1920));
  }
}
@media (min-width: 2880w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(2880 / 1920));
  }
}
@media (min-width: 3200w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(3200 / 1920));
  }
}
@media (min-width: 3440w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(3440 / 1920));
  }
}
@media (min-width: 3840w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(3840 / 1920));
  }
}
@media (min-width: 4096w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(4096 / 1920));
  }
}
@media (min-width: 4500w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(4500 / 1920));
  }
}
@media (min-width: 5120w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(5120 / 1920));
  }
}
@media (min-width: 6400w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(6400 / 1920));
  }
}
@media (min-width: 7680w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(7680 / 1920));
  }
}
@media (min-width: 8192w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(8192 / 1920));
  }
}
@media (min-width: 10240w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(10240 / 1920));
  }
}
@media (min-width: 15360w) {
  .Dashboard > .layoutGroup {
    transform: scale(calc(15360 / 1920));
  }
}
