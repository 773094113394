@value globals: "../GlobalStyles.css";
@value activeColor, defaultColor, uiBlack from globals;
@value screen-min, screen-xs-max, screen-sm-max from globals;

.Viewer {
  background-color: uiBlack;
  height: 100%;
  width: 100%;
}

.Viewer .LoadingIndicator {
  background: uiBlack !important;
}

.Viewer .viewport-section {
  bottom: 0;
  display: flex;
  height: calc(100% - 122px);
  left: 0;
  position: fixed;
  width: 100%;
}

.Viewer .viewport-section .viewport {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  width: 100%;
}

.Viewer .ViewportControl {
  left: 16px;
  position: fixed;
  top: 40px;
}

.Viewer .MeasurementControl {
  margin-left: 20px;
  padding-top: 10px;
}

.Viewer .SessionControl {
  position: fixed;
  right: 22px;
  top: 44px;
}

.Viewer .Logo {
  font-size: 15px;
  left: 27px;
  line-height: 18px;
  position: fixed;
  top: 19px;
}

.Viewer .ViewportOverlay {
  pointer-events: none;
}

.Viewer .ViewportOverlay .top-left {
  top: 10px;
}

.Viewer .ViewportOverlay .bottom-left,
.Viewer .ViewportOverlay .top-left {
  left: 27px;
}

.Viewer .ViewportOverlay .bottom-right {
  right: 32px;
}

.Viewer .magnifyTool {
  border: 4px #ffffff solid;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #1e1e1e;
  cursor: none;
  display: none;
  /* TODO: [layout]
   * The `overflow: hidden` declaration is not working for android when using 50% radius.
   * This is making the magnifying glass to be shaped as a square.
   * See: https://stackoverflow.com/questions/21584679
   */
  overflow: hidden;
  z-index: 100;
}

@media (max-width: screen-sm-max) {
  .Viewer .viewport-section {
    height: calc(100% - 100px);
  }

  .Viewer .Logo {
    display: none;
  }

  .Viewer .ViewportControl {
    top: 18px;
  }

  .Viewer .SessionControl {
    top: 22px;
  }
}

@media (max-width: screen-xs-max) {
  .Viewer .viewport-section {
    height: calc(100% - 68px);
  }

  .Viewer .ViewportOverlay .overlay-element:not(.top-left) {
    display: none;
  }

  .Viewer .ViewportOverlay .overlay-element.top-left {
    left: unset;
    right: 40px;
    top: 4px;
  }

  .Viewer
    .ViewportOverlay
    .overlay-element.top-left
    > span:not(.loadingProgress) {
    display: none;
  }

  .Viewer .SessionControl {
    right: 0;
    top: 0;
  }

  .Viewer .SessionControl .CaseProgress > * {
    border-radius: 0;
  }

  .Viewer .SessionControl .CaseProgress .increment {
    background-color: transparent;
    border-color: transparent;
    color: #ffffff;
    top: 15px;
    left: auto;
    right: -40px;
  }

  .Viewer .SessionControl .CaseControlButtons {
    bottom: 8px;
    position: fixed;
    right: 32px;
  }

  .Viewer .ViewportControl {
    background-color: #15125e;
    height: 68px;
    left: 0;
    padding-right: 416px;
    top: 0;
    width: 100%;
  }

  .Viewer .MeasurementControl {
    bottom: 8px;
    padding: 0;
    padding-right: 360px;
    position: fixed;
    left: 0;
  }

  .Viewer .MeasurementControl .lesions {
    display: none;
  }

  .Viewer .labellingComponent {
    bottom: 52px;
    top: unset !important;
  }
}

@media (max-width: screen-min) {
  .Viewer .viewport-section {
    height: calc(100% - 48px);
    position: relative;
    top: 48px;
    z-index: auto;
  }

  .Viewer .ViewportOverlay .overlay-element.top-left {
    bottom: 50px;
    left: 12px;
    top: auto;
  }

  .Viewer .SessionControl {
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons {
    bottom: 0;
    display: flex;
    padding: 0 12px 8px;
    right: 0;
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons > div {
    flex-grow: 1;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button,
  .Viewer .SessionControl .CaseControlButtons button.complete-case {
    font-size: 15px;
    height: 38px;
    line-height: 38px;
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button {
    border-radius: 9px 0 0 9px;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button .arrow {
    display: none;
  }

  .Viewer .SessionControl .CaseControlButtons button.complete-case {
    border-radius: 0 9px 9px 0;
  }

  .Viewer .SessionControl .CaseControlButtons .CaseFeedback .feedback-options {
    left: 0;
  }

  .Viewer
    .SessionControl
    .CaseControlButtons
    .CaseFeedback
    .feedback-options:before {
    left: unset;
    right: 116px;
  }

  .Viewer .SessionControl .CaseProgress {
    margin: 0;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount {
    flex-grow: 1;
    min-width: 100px;
    width: auto;
  }

  .Viewer .SessionControl .CaseProgress .icon > div {
    font-size: 15px;
    font-weight: 600;
    margin: 0 10px 0 0;
    text-align: left;
  }

  .Viewer .SessionControl .CaseProgress .icon > svg {
    min-width: 24px;
    margin: 0 10px;
  }

  .Viewer .SessionControl .CaseProgress .icon {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: auto;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount,
  .Viewer .SessionControl .CaseProgress .icon {
    height: 48px;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount .value {
    font-size: 40px;
    line-height: 30px;
  }

  .Viewer .MeasurementControl {
    bottom: auto;
    left: 0;
    margin: 0;
    padding-right: 32px;
    top: 100px;
  }

  .Viewer .ViewportControl {
    background-color: transparent;
    flex-wrap: wrap;
    height: auto;
    padding: 0;
    padding-right: 28px;
    top: 48px;
    width: 100%;
  }

  .Viewer .ToolbarSection .presetSelected {
    display: none;
  }

  .Viewer .labellingComponent {
    bottom: 50px;
    left: 6px !important;
    position: fixed;
    right: 6px !important;
    top: unset !important;
    white-space: nowrap;
    width: auto;
  }

  .Viewer .select-tree {
    width: 100%;
  }

  .Viewer .addLabelButton {
    display: none;
  }
}

@media (max-width: 400px) {
  .Viewer .SessionControl .CaseProgress .icon.instructions > div {
    display: none;
  }
}

@media (max-width: 360px) {
  .Viewer .SessionControl .CaseProgress .icon > div {
    display: none;
  }
}
