@value globals: "../GlobalStyles.css";
@value textActiveColor from globals;

.Checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto;
  transition: all 0.3s ease;
}

.Checkbox input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease;
}

.control-indicator {
  position: absolute;
  top: 6px;
  left: 0;
  height: 18px;
  width: 18px;
  background: transparent;
  border: 2px solid white;
  border-radius: 3px;
  margin-right: 20px;
  transition: all 0.3s ease;
}

.Checkbox:hover input ~ .control-indicator {
  background: rgba(255, 255, 255, 0.1);
}

.Checkbox input:checked ~ .control-indicator {
  background: #ffffff;
}

.Checkbox:hover input:not([disabled]):checked ~ .control-indicator,
.Checkbox input:checked:focus ~ .control-indicator {
  background: #0e6647d;
}

.Checkbox input:disabled ~ .control-indicator {
  background: #e6e6e6;
  opacity: 0;
  pointer-events: none;
}

.control-indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.Checkbox input:checked ~ .control-indicator:after {
  display: block;
}

.Checkbox .control-indicator:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box;
}

.Checkbox input:disabled ~ .control-indicator:after {
  border-color: #7b7b7b;
}
