@value globals: "../GlobalStyles.css";
@value screen-sm, activeColor, uiGrayDark, textSecondaryColor from globals;

@value buttonHeight: 43px;
@value buttonArrowColor: #ffffff;
@value popupArrowWidth: 12px;
@value popupArrowColor: #1e1d32;

.CaseFeedback {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.CaseFeedback .feedback-button {
  background-color: #15125e;
  border-radius: 9px 0 0 9px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: buttonHeight;
  line-height: buttonHeight;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 131px;
}

.CaseFeedback .feedback-button:disabled {
  background-color: #140046;
  color: #8684bf;
  cursor: not-allowed;
}

.CaseFeedback .feedback-button:not(:disabled):hover {
  background-color: #140046;
}

.CaseFeedback .feedback-button:not(:disabled):active,
.CaseFeedback .feedback-button[active='true'] {
  background-color: #140046;
  position: relative;
  z-index: 30;
}

.CaseFeedback .arrow {
  display: inline-block;
  height: 0;
  margin: 0 6px;
  position: relative;
  right: 0px;
  transition: transform 0.3s ease;
  width: 0;
  vertical-align: middle;
}

.CaseFeedback .arrow:before {
  border-color: transparent;
  border-style: solid;
  border-top-color: buttonArrowColor;
  border-width: 5px;
  content: '';
  height: 0;
  left: 0;
  margin-top: -2.5px;
  position: absolute;
  top: 0;
  width: 0;
}

.CaseFeedback .feedback-options {
  display: block;
  position: absolute;
  z-index: 50;
  background-color: #1e1d32;
  border-radius: 9px;
  padding: 6px 16px 16px 16px;
  width: 244px;
}

.CaseFeedback .feedback-options:before {
  content: '';
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: popupArrowWidth;
  left: 70px;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.CaseFeedback .feedback-options ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 3px;
}

.CaseFeedback .feedback-options li {
  padding: 1.5px 0;
  list-style: none;
  color: white;
}

.CaseFeedback .feedback-options .button {
  display: block;
  width: 100%;
  margin-top: 8px;
  height: 40px;
}

.CaseFeedback .button {
  display: none;
}

.caseFeedbackOptionsBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;
}

/* Responsive properties */
.CaseFeedback .feedback-options {
  bottom: calc(buttonHeight + popupArrowWidth);
}

.CaseFeedback .feedback-options:before {
  bottom: calc(popupArrowWidth * -2);
  border-top-color: popupArrowColor;
}

.CaseFeedback .arrow {
  transform: scaleY(-1);
}

.CaseFeedback .feedback-button[active='true'] .arrow {
  transform: scaleY(1);
}

@media (min-width: screen-sm) {
  .CaseFeedback .feedback-options {
    bottom: auto;
    top: calc(buttonHeight + popupArrowWidth);
  }

  .CaseFeedback .feedback-options:before {
    border-bottom-color: popupArrowColor;
    border-top-color: transparent;
    bottom: auto;
    top: calc(popupArrowWidth * -2);
  }

  .CaseFeedback .arrow {
    transform: scaleY(1);
  }

  .CaseFeedback .feedback-button[active='true'] .arrow {
    transform: scaleY(-1);
  }
}
