html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  box-sizing: border-box;
  font-family: 'Work Sans', 'SF Pro Text', 'Roboto', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.fixed-page {
  position: fixed;
  overflow: hidden;
}

@-webkit-keyframes pageEnter {
  0%, 49% {
    visibility: hidden;
  }
  50%, 100% {
    visibility: visible;
  }
}

@keyframes pageEnter {
  0%, 49% {
    visibility: hidden;
  }
  50%, 100% {
    visibility: visible;
  }
}

.route-container {
  width: 100%;
  height: 100%;
}

.route-section {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
}

.route-section > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.route-section > .fade-enter {
  -webkit-animation: pageEnter 2s linear;
          animation: pageEnter 2s linear;
}

.route-section > .fade-exit {
  z-index: 2;
}

@-webkit-keyframes dashboardTopNavEnter {
  0%,
  40% {
    opacity: 0;
  }
  82%,
  100% {
    opacity: 1;
  }
}

@keyframes dashboardTopNavEnter {
  0%,
  40% {
    opacity: 0;
  }
  82%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes dashboardCasesEnter {
  0%,
  55% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes dashboardCasesEnter {
  0%,
  55% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes dashboardCommunityEnter {
  0%,
  40% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  82%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes dashboardCommunityEnter {
  0%,
  40% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  82%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes dashboardBadgesEnter {
  0%,
  16% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  60%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes dashboardBadgesEnter {
  0%,
  16% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  60%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes dashboardScoreEnter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  43%, 100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes dashboardScoreEnter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  43%, 100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fade-enter.fade-enter-active.Dashboard .simpleHeader {
  -webkit-animation: dashboardTopNavEnter 1s ease-out 1s;
          animation: dashboardTopNavEnter 1s ease-out 1s;
  opacity: 0;
}

.fade-enter.fade-enter-active.Dashboard .CaseTypeSection {
  -webkit-animation: dashboardCasesEnter 1s ease-out 1s;
          animation: dashboardCasesEnter 1s ease-out 1s;
  opacity: 0;
  -webkit-transform: translateY(400px);
          transform: translateY(400px);
}

.fade-enter.fade-enter-active.Dashboard .ActivityProgressSection {
  -webkit-animation: dashboardScoreEnter 1s ease-out 1s;
          animation: dashboardScoreEnter 1s ease-out 1s;
  opacity: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.fade-enter.fade-enter-active.Dashboard .AchievementSection {
  -webkit-animation: dashboardBadgesEnter 1s ease-out 1s;
          animation: dashboardBadgesEnter 1s ease-out 1s;
  opacity: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.fade-enter.fade-enter-active.Dashboard .StatisticsSection {
  -webkit-animation: dashboardCommunityEnter 1s ease-out 1s;
          animation: dashboardCommunityEnter 1s ease-out 1s;
  opacity: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

/* State palette */

/* Interface UI Colors */

/* Text Colors */

/* Other Colors */

/* Screen resolutions */

/* Grid breakpoints */

body {
  background-color: #140046;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.Dashboard > .layoutGroup {
  margin: 0 auto;
  max-width: 1920px;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
}

.rankBadge.not-received img {
  opacity: 0.25;
}

.link {
  border: 0;
  background-color: transparent;
  padding: 0;
}

@media (min-width: 2048px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(2048 / 1920));
            transform: scale(calc(2048 / 1920));
  }
}
@media (min-width: 2280px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(2280 / 1920));
            transform: scale(calc(2280 / 1920));
  }
}
@media (min-width: 2560px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(2560 / 1920));
            transform: scale(calc(2560 / 1920));
  }
}
@media (min-width: 2880px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(2880 / 1920));
            transform: scale(calc(2880 / 1920));
  }
}
@media (min-width: 3200px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(3200 / 1920));
            transform: scale(calc(3200 / 1920));
  }
}
@media (min-width: 3440px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(3440 / 1920));
            transform: scale(calc(3440 / 1920));
  }
}
@media (min-width: 3840px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(3840 / 1920));
            transform: scale(calc(3840 / 1920));
  }
}
@media (min-width: 4096px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(4096 / 1920));
            transform: scale(calc(4096 / 1920));
  }
}
@media (min-width: 4500px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(4500 / 1920));
            transform: scale(calc(4500 / 1920));
  }
}
@media (min-width: 5120px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(5120 / 1920));
            transform: scale(calc(5120 / 1920));
  }
}
@media (min-width: 6400px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(6400 / 1920));
            transform: scale(calc(6400 / 1920));
  }
}
@media (min-width: 7680px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(7680 / 1920));
            transform: scale(calc(7680 / 1920));
  }
}
@media (min-width: 8192px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(8192 / 1920));
            transform: scale(calc(8192 / 1920));
  }
}
@media (min-width: 10240px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(10240 / 1920));
            transform: scale(calc(10240 / 1920));
  }
}
@media (min-width: 15360px) {
  .Dashboard > .layoutGroup {
    -webkit-transform: scale(calc(15360 / 1920));
            transform: scale(calc(15360 / 1920));
  }
}

@-webkit-keyframes dashboardTopNavExit {
  0% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
}

@keyframes dashboardTopNavExit {
  0% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes dashboardCasesExit {
  0%,
  13% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  51% {
    opacity: 0;
  }
  58%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
}

@keyframes dashboardCasesExit {
  0%,
  13% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  51% {
    opacity: 0;
  }
  58%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
}

@-webkit-keyframes dashboardCommunityExit {
  0%,
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  57% {
    opacity: 0;
  }
  66%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@keyframes dashboardCommunityExit {
  0%,
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  57% {
    opacity: 0;
  }
  66%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@-webkit-keyframes dashboardBadgesExit {
  0%,
  28% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  64% {
    opacity: 0;
  }
  72%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@keyframes dashboardBadgesExit {
  0%,
  28% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  64% {
    opacity: 0;
  }
  72%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@-webkit-keyframes dashboardScoreExit {
  0%,
  35% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  72% {
    opacity: 0;
  }
  80%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@keyframes dashboardScoreExit {
  0%,
  35% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  72% {
    opacity: 0;
  }
  80%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@-webkit-keyframes dashboardToViewerBackground {
  0% {
    background-color: #140046;
  }
  100% {
    background-color: #000000;
  }
}

@keyframes dashboardToViewerBackground {
  0% {
    background-color: #140046;
  }
  100% {
    background-color: #000000;
  }
}

.fade-exit.fade-exit-active.Dashboard .simpleHeader {
  -webkit-animation: dashboardTopNavExit 1s ease-out;
          animation: dashboardTopNavExit 1s ease-out;
}

.fade-exit.fade-exit-active.Dashboard .CaseTypeSection {
  -webkit-animation: dashboardCasesExit 1s ease-out;
          animation: dashboardCasesExit 1s ease-out;
}

.fade-exit.fade-exit-active.Dashboard .ActivityProgressSection {
  -webkit-animation: dashboardScoreExit 1s ease-out;
          animation: dashboardScoreExit 1s ease-out;
}

.fade-exit.fade-exit-active.Dashboard .AchievementSection {
  -webkit-animation: dashboardBadgesExit 1s ease-out;
          animation: dashboardBadgesExit 1s ease-out;
}

.fade-exit.fade-exit-active.Dashboard .StatisticsSection {
  -webkit-animation: dashboardCommunityExit 1s ease-out;
          animation: dashboardCommunityExit 1s ease-out;
}

.fade-enter.Viewer ~ .fade-exit.fade-exit-active.Dashboard {
  -webkit-animation: dashboardToViewerBackground 1s ease-out;
          animation: dashboardToViewerBackground 1s ease-out;
}

@-webkit-keyframes viewerViewportEnter {
  0% {
    opacity: 0;
  }
  32%,
  100% {
    opacity: 1;
  }
}

@keyframes viewerViewportEnter {
  0% {
    opacity: 0;
  }
  32%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes viewerToolsEnter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px);
  }
  32%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes viewerToolsEnter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px);
  }
  32%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes viewerSessionControlEnter {
  0%,
  22% {
    opacity: 0;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
  54%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes viewerSessionControlEnter {
  0%,
  22% {
    opacity: 0;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
  54%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fade-enter.fade-enter-active.Viewer .Logo,
.fade-enter.fade-enter-active.Viewer .ViewportControl {
  -webkit-animation: viewerToolsEnter 1s ease-out 1s;
          animation: viewerToolsEnter 1s ease-out 1s;
  opacity: 0;
  -webkit-transform: translateY(-90px);
          transform: translateY(-90px);
}

@media (min-width: 1024px) {
  .fade-enter.fade-enter-active.Viewer .SessionControl {
    -webkit-animation: viewerSessionControlEnter 1s ease-out 1s;
            animation: viewerSessionControlEnter 1s ease-out 1s;
    opacity: 0;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
}

@media (min-width: 640px) {
  .fade-enter.fade-enter-active.Viewer .viewport-section,
  .fade-enter.fade-enter-active.Viewer .LoadingIndicator {
    -webkit-animation: viewerViewportEnter 1s ease-out 1s;
            animation: viewerViewportEnter 1s ease-out 1s;
    opacity: 0;
  }
}
@-webkit-keyframes viewerBackgroundExit {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #140046;
  }
}

@keyframes viewerBackgroundExit {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #140046;
  }
}

@-webkit-keyframes viewerViewportExit {
  0% {
    opacity: 1;
  }
  32%,
  100% {
    opacity: 0;
  }
}

@keyframes viewerViewportExit {
  0% {
    opacity: 1;
  }
  32%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes viewerToolsExit {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  32%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px);
  }
}

@keyframes viewerToolsExit {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  32%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px);
  }
}

@-webkit-keyframes viewerSessionControlExit {
  0%,
  8% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
}

@keyframes viewerSessionControlExit {
  0%,
  8% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
}

.fade-exit.fade-exit-active.Viewer {
  -webkit-animation: viewerBackgroundExit 1s ease-out;
          animation: viewerBackgroundExit 1s ease-out;
}

.fade-exit.fade-exit-active.Viewer .Logo,
.fade-exit.fade-exit-active.Viewer .ViewportControl {
  -webkit-animation: viewerToolsExit 1s ease-out;
          animation: viewerToolsExit 1s ease-out;
}

@media (min-width: 1024px) {
  .fade-exit.fade-exit-active.Viewer .SessionControl {
    -webkit-animation: viewerSessionControlExit 1s ease-out;
            animation: viewerSessionControlExit 1s ease-out;
  }
}

@media (min-width: 640px) {
  .fade-exit.fade-exit-active.Viewer .viewport-section,
  .fade-exit.fade-exit-active.Viewer .LoadingIndicator {
    -webkit-animation: viewerViewportExit 1s ease-out;
            animation: viewerViewportExit 1s ease-out;
  }
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-13,
.col-14,
.col-15,
.col-16,
.col,
.col-auto,
.col-third .col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-13,
.col-sm-14,
.col-sm-15,
.col-sm-16,
.col-sm,
.col-sm-auto,
.col-sm-third .col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-13,
.col-md-14,
.col-md-15,
.col-md-16,
.col-md,
.col-md-auto,
.col-md-third,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-13,
.col-lg-14,
.col-lg-15,
.col-lg-16,
.col-lg,
.col-lg-auto,
.col-lg-third,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-13,
.col-xl-14,
.col-xl-15,
.col-xl-16,
.col-xl,
.col-xl-auto,
.col-xl-third {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-third {
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
}

.col-1 {
  flex: 0 0 6.25%;
  max-width: 6.25%;
}

.col-2 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-3 {
  flex: 0 0 18.75%;
  max-width: 18.75%;
}

.col-4 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-5 {
  flex: 0 0 31.25%;
  max-width: 31.25%;
}

.col-6 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-7 {
  flex: 0 0 43.75%;
  max-width: 43.75%;
}

.col-8 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-9 {
  flex: 0 0 56.25%;
  max-width: 56.25%;
}

.col-10 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-11 {
  flex: 0 0 68.75%;
  max-width: 68.75%;
}

.col-12 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-13 {
  flex: 0 0 81.25%;
  max-width: 81.25%;
}

.col-14 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-15 {
  flex: 0 0 93.75%;
  max-width: 93.75%;
}

.col-16 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 17;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.offset-1 {
  margin-left: 6.25%;
}

.offset-2 {
  margin-left: 12.5%;
}

.offset-3 {
  margin-left: 18.75%;
}

.offset-4 {
  margin-left: 25%;
}

.offset-5 {
  margin-left: 31.25%;
}

.offset-6 {
  margin-left: 37.5%;
}

.offset-7 {
  margin-left: 43.75%;
}

.offset-8 {
  margin-left: 50%;
}

.offset-9 {
  margin-left: 56.25%;
}

.offset-10 {
  margin-left: 62.5%;
}

.offset-11 {
  margin-left: 68.75%;
}

.offset-12 {
  margin-left: 75%;
}

.offset-13 {
  margin-left: 81.25%;
}

.offset-14 {
  margin-left: 87.5%;
}

.offset-15 {
  margin-left: 93.75%;
}

.offset-third {
  margin-left: onde-third;
}

@media (min-width: 640px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xs-third {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .col-xs-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
  .col-xs-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xs-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }
  .col-xs-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }
  .col-xs-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-xs-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }
  .col-xs-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }
  .col-xs-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-xs-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }
  .col-xs-12 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }
  .col-xs-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-xs-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }
  .col-xs-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 17;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .order-xs-13 {
    order: 13;
  }
  .order-xs-14 {
    order: 14;
  }
  .order-xs-15 {
    order: 15;
  }
  .order-xs-16 {
    order: 16;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 6.25%;
  }
  .offset-xs-2 {
    margin-left: 12.5%;
  }
  .offset-xs-3 {
    margin-left: 18.75%;
  }
  .offset-xs-4 {
    margin-left: 25%;
  }
  .offset-xs-5 {
    margin-left: 31.25%;
  }
  .offset-xs-6 {
    margin-left: 37.5%;
  }
  .offset-xs-7 {
    margin-left: 43.75%;
  }
  .offset-xs-8 {
    margin-left: 50%;
  }
  .offset-xs-9 {
    margin-left: 56.25%;
  }
  .offset-xs-10 {
    margin-left: 62.5%;
  }
  .offset-xs-11 {
    margin-left: 68.75%;
  }
  .offset-xs-12 {
    margin-left: 75%;
  }
  .offset-xs-13 {
    margin-left: 81.25%;
  }
  .offset-xs-14 {
    margin-left: 87.5%;
  }
  .offset-xs-15 {
    margin-left: 93.75%;
  }
  .offset-xs-third {
    margin-left: onde-third;
  }
}

@media (min-width: 1024px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-third {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .col-sm-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
  .col-sm-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-sm-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }
  .col-sm-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }
  .col-sm-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-sm-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }
  .col-sm-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }
  .col-sm-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-sm-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }
  .col-sm-12 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }
  .col-sm-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-sm-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }
  .col-sm-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 17;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-13 {
    order: 13;
  }
  .order-sm-14 {
    order: 14;
  }
  .order-sm-15 {
    order: 15;
  }
  .order-sm-16 {
    order: 16;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 6.25%;
  }
  .offset-sm-2 {
    margin-left: 12.5%;
  }
  .offset-sm-3 {
    margin-left: 18.75%;
  }
  .offset-sm-4 {
    margin-left: 25%;
  }
  .offset-sm-5 {
    margin-left: 31.25%;
  }
  .offset-sm-6 {
    margin-left: 37.5%;
  }
  .offset-sm-7 {
    margin-left: 43.75%;
  }
  .offset-sm-8 {
    margin-left: 50%;
  }
  .offset-sm-9 {
    margin-left: 56.25%;
  }
  .offset-sm-10 {
    margin-left: 62.5%;
  }
  .offset-sm-11 {
    margin-left: 68.75%;
  }
  .offset-sm-12 {
    margin-left: 75%;
  }
  .offset-sm-13 {
    margin-left: 81.25%;
  }
  .offset-sm-14 {
    margin-left: 87.5%;
  }
  .offset-sm-15 {
    margin-left: 93.75%;
  }
  .offset-sm-third {
    margin-left: onde-third;
  }
}

@media (min-width: 1366px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-third {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .col-md-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
  .col-md-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-md-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }
  .col-md-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }
  .col-md-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-md-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }
  .col-md-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }
  .col-md-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-md-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }
  .col-md-12 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }
  .col-md-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-md-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }
  .col-md-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 17;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-13 {
    order: 13;
  }
  .order-md-14 {
    order: 14;
  }
  .order-md-15 {
    order: 15;
  }
  .order-md-16 {
    order: 16;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 6.25%;
  }
  .offset-md-2 {
    margin-left: 12.5%;
  }
  .offset-md-3 {
    margin-left: 18.75%;
  }
  .offset-md-4 {
    margin-left: 25%;
  }
  .offset-md-5 {
    margin-left: 31.25%;
  }
  .offset-md-6 {
    margin-left: 37.5%;
  }
  .offset-md-7 {
    margin-left: 43.75%;
  }
  .offset-md-8 {
    margin-left: 50%;
  }
  .offset-md-9 {
    margin-left: 56.25%;
  }
  .offset-md-10 {
    margin-left: 62.5%;
  }
  .offset-md-11 {
    margin-left: 68.75%;
  }
  .offset-md-12 {
    margin-left: 75%;
  }
  .offset-md-13 {
    margin-left: 81.25%;
  }
  .offset-md-14 {
    margin-left: 87.5%;
  }
  .offset-md-15 {
    margin-left: 93.75%;
  }
  .offset-md-third {
    margin-left: onde-third;
  }
}

@media (min-width: 1680px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-third {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .col-lg-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
  .col-lg-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-lg-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }
  .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }
  .col-lg-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-lg-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }
  .col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }
  .col-lg-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-lg-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }
  .col-lg-12 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }
  .col-lg-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-lg-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }
  .col-lg-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 17;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-13 {
    order: 13;
  }
  .order-lg-14 {
    order: 14;
  }
  .order-lg-15 {
    order: 15;
  }
  .order-lg-16 {
    order: 16;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 6.25%;
  }
  .offset-lg-2 {
    margin-left: 12.5%;
  }
  .offset-lg-3 {
    margin-left: 18.75%;
  }
  .offset-lg-4 {
    margin-left: 25%;
  }
  .offset-lg-5 {
    margin-left: 31.25%;
  }
  .offset-lg-6 {
    margin-left: 37.5%;
  }
  .offset-lg-7 {
    margin-left: 43.75%;
  }
  .offset-lg-8 {
    margin-left: 50%;
  }
  .offset-lg-9 {
    margin-left: 56.25%;
  }
  .offset-lg-10 {
    margin-left: 62.5%;
  }
  .offset-lg-11 {
    margin-left: 68.75%;
  }
  .offset-lg-12 {
    margin-left: 75%;
  }
  .offset-lg-13 {
    margin-left: 81.25%;
  }
  .offset-lg-14 {
    margin-left: 87.5%;
  }
  .offset-lg-15 {
    margin-left: 93.75%;
  }
  .offset-lg-third {
    margin-left: onde-third;
  }
}

@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-third {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .col-xl-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
  .col-xl-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xl-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }
  .col-xl-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }
  .col-xl-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-xl-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }
  .col-xl-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }
  .col-xl-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-xl-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }
  .col-xl-12 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }
  .col-xl-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-xl-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }
  .col-xl-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 17;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-13 {
    order: 13;
  }
  .order-xl-14 {
    order: 14;
  }
  .order-xl-15 {
    order: 15;
  }
  .order-xl-16 {
    order: 16;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 6.25%;
  }
  .offset-xl-2 {
    margin-left: 12.5%;
  }
  .offset-xl-3 {
    margin-left: 18.75%;
  }
  .offset-xl-4 {
    margin-left: 25%;
  }
  .offset-xl-5 {
    margin-left: 31.25%;
  }
  .offset-xl-6 {
    margin-left: 37.5%;
  }
  .offset-xl-7 {
    margin-left: 43.75%;
  }
  .offset-xl-8 {
    margin-left: 50%;
  }
  .offset-xl-9 {
    margin-left: 56.25%;
  }
  .offset-xl-10 {
    margin-left: 62.5%;
  }
  .offset-xl-11 {
    margin-left: 68.75%;
  }
  .offset-xl-12 {
    margin-left: 75%;
  }
  .offset-xl-13 {
    margin-left: 81.25%;
  }
  .offset-xl-14 {
    margin-left: 87.5%;
  }
  .offset-xl-15 {
    margin-left: 93.75%;
  }
  .offset-xl-third {
    margin-left: onde-third;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 640px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1366px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1680px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1920px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 640px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1366px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1680px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1920px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.LoadingIndicator {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #140046;
  pointer-events: none; /* Necessary for click-through to cornerstone element below*/
  z-index: 1;
  text-align: center;
}

.LoadingIndicator .indicatorContents {
  position: absolute;
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 25%;
  min-width: 100px;
  max-width: 200px;
  height: 25%;
  min-height: 100px;
  max-height: 200px;
}

.LoadingIndicator .indicatorContents span {
  color: #7cf4fe;
  display: block;
  padding: 15px;
}

.LoadingIndicator .indicatorContents svg {
  display: block;
  margin: 0 auto;
  fill: #7cf4fe;
  stroke: #7cf4fe;
  width: 45px;
  height: 45px;
  -webkit-animation: rotate 2.4s linear infinite;
          animation: rotate 2.4s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.StatisticsCard {
  box-sizing: border-box;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 24px;
  padding: 0 20px;
  white-space: nowrap;
}

.StatisticsCard .lineGroup {
  margin: 25px 0 15px;
}

.StatisticsCard .number {
  color: #f2e0f7;
  display: inline-block;
  font-weight: normal;
  font-size: 50px;
  line-height: 50px;
  position: relative;
  vertical-align: top;
}

.StatisticsCard .number .maxWidth {
  opacity: 0;
}

.StatisticsCard .number .visible {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.StatisticsCard .icon {
  display: inline-block;
  height: 50px;
  margin-right: 5px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 48px;
}

.StatisticsCard .icon img {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.StatisticsCard .description {
  display: block;
  font-weight: normal;
  font-size: 16px;
  color: #f2e0f7;
  line-height: 16px;
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .StatisticsCard {
    padding: 0 30px;
  }

  .StatisticsCard .lineGroup {
    margin-top: 32px;
  }
}

@media (min-width: 1680px) {
  .StatisticsCard {
    padding: 0 15px;
  }
}

@media (min-width: 1920px) {
  .StatisticsCard {
    padding: 0 30px;
  }
}

.InfoBox {
  background-color: #516873;
  border-radius: 6px;
  box-shadow: 8px 8px 0 0 #030628;
  margin-bottom: 18px;
  overflow: hidden;
  padding: 0;
}

.InfoBox .boxHeader {
  background-color: #263340;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
}

.InfoBox .boxContent {
  padding: 10px;
}

@media (min-width: 1024px) {
  .InfoBox {
    box-shadow: 9px 9px 0 0 #030628;
    margin-bottom: 34px;
  }
}

@media (min-width: 1024px) {
  .InfoBox {
    margin-bottom: 45px;
  }
}

@media (min-width: 1680px) {
  .InfoBox {
    box-shadow: 13px 13px 0 0 #030628;
    margin-bottom: 50px;
  }
}

.StatisticsSection.InfoBox {
  background-color: #471448;
}

.StatisticsSection.InfoBox .boxHeader {
  background-color: #3d0c3e;
}

.StatisticsSection.InfoBox .boxContent {
  padding-bottom: 50px;
}

@media (min-width: 1024px) {
  .StatisticsSection.InfoBox .boxContent {
    display: table;
    margin: 0 auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1680px) {
  .StatisticsSection .cardContainer:first-child .StatisticsCard {
    padding-left: 0;
  }

  .StatisticsSection .cardContainer:last-child .StatisticsCard {
    padding-right: 0;
  }
}

.ActivityProgressSection.InfoBox {
  background-color: #472e93;
}

.ActivityProgressSection.InfoBox .boxHeader {
  background-color: #2f1973;
}

.ActivityProgressSection.InfoBox .boxContent {
  padding: 16px 20px;
  position: relative;
}

.ActivityProgressSection .ProgressSection .rankBadge {
  display: inline-block;
}

.ActivityProgressSection .ProgressSection .rankBadge img {
  cursor: pointer;
  height: 92px;
  width: 92px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  vertical-align: top;
}

.ActivityProgressSection .ProgressSection .numberCases {
  justify-content: left;
}

.ActivityProgressSection .ProgressSection .value {
  color: #7cf4fe;
  display: inline-block;
  font-size: 61px;
  font-weight: 500;
  line-height: 92px;
  vertical-align: top;
}

.ActivityProgressSection .leaderboardRank {
  position: absolute;
  right: 16px;
  top: 19px;
}

.ActivityProgressSection .leaderboardRank .position {
  background-color: #140046;
  border: solid 1px #9c7dfb;
  border-radius: 21px 3px;
  color: #7cf4fe;
  font-size: 30px;
  font-weight: normal;
  height: 42px;
  line-height: 42px;
  margin-left: auto;
  text-align: center;
  width: 95px;
}

.ActivityProgressSection .leaderboardRank .description {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38;
  margin-top: 10px;
  margin-left: auto;
  text-align: center;
  width: 95px;
}

@media (min-width: 1024px) {
  .ActivityProgressSection.InfoBox .boxContent {
    padding: 10px 32px 16px;
  }

  .ActivityProgressSection .ProgressSection .rankBadge img {
    height: 130px;
    width: 130px;
  }

  .ActivityProgressSection .ProgressSection .value {
    font-size: 82px;
    line-height: 130px;
  }

  .ActivityProgressSection .plusPoints {
    font-size: 60px;
    top: -70px;
    left: 90px;
  }
}

@media (min-width: 1366px) {
  .ActivityProgressSection .ProgressSection .rankBadge img {
    height: 160px;
    width: 160px;
  }

  .ActivityProgressSection .ProgressSection .value {
    line-height: 160px;
  }
}

@media (min-width: 1680px) {
  .ActivityProgressSection.InfoBox .boxContent {
    padding: 20px 32px;
  }

  .ActivityProgressSection .progressBarContainer {
    margin-bottom: 20px;
  }
}

.ProgressBarWrapper {
  height: 12px;
  margin: 10px 0;
  position: relative;
  width: 100%;
}

.ProgressBarWrapper .startNumber,
.ProgressBarWrapper .endNumber {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  height: 21px;
  position: absolute;
  top: -32px;
  width: 50%;
}

.ProgressBarWrapper .startNumber {
  left: 0;
  text-align: left;
}

.ProgressBarWrapper .endNumber {
  right: 0;
  text-align: right;
}

.ProgressBar {
  background-color: #140046;
  height: 100%;
  width: 100%;
}

.ProgressBar .ProgressValue {
  background-image: linear-gradient(to right, #7cf4fe, #b994fe);
  height: 100%;
  position: relative;
  transition: all 3s ease-in-out;
}

.ProgressBar .ProgressValue:before {
  background-image: linear-gradient(to right, #7cf4fe, #b994fe);
  content: '';
  -webkit-filter: blur(9.7px);
          filter: blur(9.7px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ProgressBar.valueReset .ProgressValue {
  transition-duration: 0.2s;
  transition-delay: 1.8s;
}

.ProgressBar.valueReset {
  -webkit-animation: progressBarReset 2s;
          animation: progressBarReset 2s;
}

@-webkit-keyframes progressBarReset {
  0% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  25% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  80% {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    -webkit-transform: scaleY(1.2);
            transform: scaleY(1.2);
  }
  100% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes progressBarReset {
  0% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  25% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  80% {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    -webkit-transform: scaleY(1.2);
            transform: scaleY(1.2);
  }
  100% {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

.rankBadge.rankFadeIn {
  -webkit-animation: rankFadeIn 1.5s ease-in-out;
          animation: rankFadeIn 1.5s ease-in-out;
}

.rankBadge.rankFadeOut {
  -webkit-animation: rankFadeOut 0.5s ease-out;
          animation: rankFadeOut 0.5s ease-out;
}

@-webkit-keyframes rankFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  20% {
    -webkit-filter: drop-shadow(0 0 20px white);
            filter: drop-shadow(0 0 20px white);
    opacity: 1;
  }
  60% {
    -webkit-filter: drop-shadow(0 0 20px white);
            filter: drop-shadow(0 0 20px white);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes rankFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  20% {
    -webkit-filter: drop-shadow(0 0 20px white);
            filter: drop-shadow(0 0 20px white);
    opacity: 1;
  }
  60% {
    -webkit-filter: drop-shadow(0 0 20px white);
            filter: drop-shadow(0 0 20px white);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes rankFadeOut {
  0% {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
  }
  100% {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes rankFadeOut {
  0% {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
  }
  100% {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.Modal .rankBadge {
  margin: 20px 0;
}

.Modal .rankBadge .badgeName,
.Modal .rankBadge .badgeRequirement {
  margin: 0;
  text-align: center;
}

.Modal .rankBadge .badgeName {
  color: #ffffff;
}

.ProgressSection .title {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}

.ProgressSection .numberCases {
  display: flex;
  justify-content: center;
}

.ProgressSection .currentPoints {
  margin-left: 10px;
  position: relative;
}

.ProgressSection .value {
  display: inline-block;
  color: #ffffff;
  font-weight: 600;
  font-size: 120px;
  line-height: 184px;
  position: relative;
  top: 0px;
}

.ProgressSection .value .maxWidth {
  visibility: hidden;
}

.ProgressSection .value .visible {
  left: 0;
  position: absolute;
  top: 0;
}

.ProgressSection .plusPoints {
  transition: all 0.3s linear;
  color: #7cf4fe;
  position: absolute;
  font-size: 33px;
  font-weight: 600;
  top: -35px;
  left: 55px;
  opacity: 0;
}

.ProgressSection .rankBadge img {
  height: 184px;
  width: 184px;
}

@media (min-width: 1024px) {
  .ProgressSection .rankBadge {
    cursor: pointer;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .ProgressSection .rankBadge:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@media (max-width: 1023px) {
  .ProgressSection .rankBadge {
    cursor: auto;
  }
}

@media (max-width: 639px) {
  .ProgressSection .rankBadge img {
    height: 128px;
    width: 128px;
  }

  .ProgressSection .currentPoints {
    margin-left: 0;
  }
}

.CaseTypeCard {
  background-color: #000000;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
}

.CaseTypeCard .imageContainer {
  border-bottom: 2px solid #24225b;
  height: 268px;
  padding: 22px 0;
  text-align: center;
  overflow: hidden;
}

.CaseTypeCard .imageContainer img {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s;
  height: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.CaseTypeCard .infoIcon {
  fill: #034045;
  height: 20px;
  opacity: 0.41;
  position: absolute;
  right: 10px;
  stroke: #7cf4fe;
  top: 10px;
  transition: opacity 0.3s ease;
  width: 20px;
  z-index: 2;
}

.CaseTypeCard .infoIcon:hover {
  opacity: 1;
}

.CaseTypeCard .infoIcon svg {
  height: 20px;
  width: 20px;
}

.CaseTypeCard .infoContainer {
  background-color: #090020;
  height: 112px;
  padding: 16px;
}

.CaseTypeCard .infoContainer .progress {
  background-color: #28008c;
  border: 1px solid #40297b;
  border-radius: 5px;
  height: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 75px;
}

.CaseTypeCard .infoContainer .progress .current {
  -webkit-animation: slideRightIn 3s;
          animation: slideRightIn 3s;
  background-color: #7cf4fe;
  border-radius: 4px;
  display: table;
  height: 100%;
  min-width: 8px;
}

.CaseTypeCard .infoContainer .typeName {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s;
  color: #7cf4fe;
  font-size: 19px;
  font-weight: normal;
  line-height: 1.45;
  transition: color 0.3s ease;
}

.CaseTypeCard .imageContainer .spinner {
  display: none;
}

.CaseTypeCard.placeholder {
  cursor: default;
}

.CaseTypeCard.placeholder .infoIcon {
  display: none;
}

.CaseTypeCard.placeholder .infoContainer .progress .current {
  display: none;
}

.CaseTypeCard.placeholder:hover:after {
  border-color: transparent;
}

.CaseTypeCard.placeholder .imageContainer img {
  display: none;
}

.CaseTypeCard.placeholder .imageContainer .spinner {
  display: block;
  height: 100%;
}

.CaseTypeCard.placeholder .imageContainer .spinner svg {
  -webkit-animation: rotate 1s linear infinite;
          animation: rotate 1s linear infinite;
  display: block;
  fill: #ffffff;
  height: 100%;
  margin: 0 auto;
  opacity: 0.5;
  width: 80px;
}

@media (min-width: 1024px) {
  .CaseTypeCard {
    margin-bottom: 22px;
  }

  .CaseTypeCard:hover .imageContainer img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .CaseTypeCard:after {
    border: 4px solid transparent;
    border-radius: 9px;
    bottom: -10px;
    content: '';
    left: -10px;
    pointer-events: none;
    position: absolute;
    right: -10px;
    top: -10px;
    transition: border-color 0.3s ease;
  }

  .CaseTypeCard:hover:after {
    border-color: #7cf4fe;
  }

  .CaseTypeCard:hover .infoContainer .typeName {
    color: #ffffff;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideRightIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideRightIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.CaseTypeSection .CaseTypeCard.placeholder.placeholder-1,
.CaseTypeSection .CaseTypeCard.placeholder.placeholder-2,
.CaseTypeSection .CaseTypeCard.placeholder.placeholder-3 {
  display: none;
}

@media (min-width: 640px) {
  .CaseTypeSection .CaseTypeCard.placeholder.placeholder-1 {
    display: block;
  }
}

@media (min-width: 1024px) {
  .CaseTypeSection .CaseTypeCard.placeholder.placeholder-2 {
    display: block;
  }
}

@media (min-width: 1366px) {
  .CaseTypeSection .CaseTypeCard.placeholder.placeholder-3 {
    display: block;
  }
}

@media (min-width: 1680px) {
  .CaseTypeSection .CaseTypeCard.placeholder.placeholder-3 {
    display: none;
  }

  .CaseTypeSection {
    margin-right: 26px;
  }
}

.simpleHeader {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 12px;
}

.simpleHeader .userIcon {
  display: inline-block;
}

.simpleHeader .userIcon img {
  width: 100%;
  height: 100%;
}

.simpleHeader .userSection .username {
  color: #a592d2;
  font-size: 17px;
  font-weight: normal;
  padding-left: 10px;
}

.simpleHeader .userSection > * {
  vertical-align: top;
}

.simpleHeader .actionSection > * {
  color: #7cf4fe;
  cursor: pointer;
  font-family: 'SF Pro Text', 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-left: 42px;
  text-decoration: none;
}

.simpleHeader .actionSection > *:first-child {
  margin-left: 0 !important;
}

.simpleHeader {
  height: 158px;
  padding: 15px 12px;
}

.simpleHeader .Logo {
  font-size: 20px;
  line-height: 36px;
  white-space: nowrap;
}

.simpleHeader .userSection {
  flex-grow: 1;
  white-space: nowrap;
}

.simpleHeader .actionSection {
  white-space: nowrap;
}

.simpleHeader .userIcon {
  border-radius: calc(36px / 2);
  height: 36px;
  width: 36px;
}

@media (min-width: 640px) {
  .simpleHeader {
    height: 94px;
    padding: 10px 12px;
  }

  .simpleHeader .Logo {
    display: block;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    width: 100%;
  }

  .simpleHeader .userSection .username {
    max-width: 210px;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    display: inline-block;
    line-height: 36px;
  }

  .simpleHeader .userIcon {
    border-radius: calc(36px / 2);
    height: 36px;
    width: 36px;
  }

  .simpleHeader .actionSection {
    padding-right: 5px;
  }

  .simpleHeader .actionSection > * {
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  .simpleHeader {
    align-content: stretch;
    height: 94px;
    padding: 10px 35px;
  }

  .simpleHeader .userSection .username {
    max-width: unset;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    line-height: 42px;
  }

  .simpleHeader .actionSection > * {
    transition: color 0.3s ease;
  }

  .simpleHeader .actionSection > *:hover {
    color: #ffffff;
  }

  .simpleHeader .userSection .userIcon {
    border-radius: calc(42px / 2);
    height: 42px;
    width: 42px;
  }

  .simpleHeader .userSection .username {
    font-size: 24px;
    padding-left: 12px;
  }

  .simpleHeader .actionSection > * {
    margin-left: 28px;
  }
}

@media (min-width: 1366px) {
  .simpleHeader {
    height: 130px;
    padding: 16px 60px;
  }

  .simpleHeader .Logo {
    font-size: 32px;
    line-height: 38px;
  }

  .simpleHeader .userSection .username,
  .simpleHeader .actionSection > * {
    line-height: 60px;
  }

  .simpleHeader .userSection .userIcon {
    border-radius: calc(60px / 2);
    height: 60px;
    width: 60px;
  }

  .simpleHeader .userSection .username {
    font-size: 26px;
    padding-left: 20px;
  }
}

@media (min-width: 1680px) {
  .simpleHeader {
    flex-wrap: nowrap;
    height: 140px;
    padding: 30px 80px;
    position: relative;
  }

  .simpleHeader .Logo {
    line-height: 60px;
    text-align: left;
    width: calc(62.8% + 10px);
  }

  .simpleHeader .actionSection {
    padding: 0;
    position: absolute;
    left: calc(62.8% + 71px);
    top: 90px;
  }

  .simpleHeader .actionSection > * {
    height: 32px;
    line-height: 32px;
    margin-left: 0;
    margin-right: 28px;
  }
}

@media (max-width: 639px) {
  .simpleHeader .Logo,
  .simpleHeader .userSection,
  .simpleHeader .actionSection {
    flex-basis: 100%;
  }

  .simpleHeader .userSection {
    margin: 15px 0;
  }

  .simpleHeader .userSection .username {
    line-height: 36px;
    padding-left: 6px;
  }

  .simpleHeader .actionSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding-right: 5px;
    max-width: 360px;
  }

  .simpleHeader .actionSection > * {
    margin: 0;
  }
}

.Logo {
  cursor: pointer;
  display: inline-block;
}

.Logo .logoText {
  color: #ffffff;
  font-weight: 600;
}

.Logo .logoText.highlight {
  color: #7cf4fe;
}

.AchievementBadge {
  background-color: #1b2d63;
  border: 4px solid #2271a0;
  border-radius: 50%;
  display: inline-block;
  height: 68px;
  overflow: hidden;
  width: 68px;
}

.AchievementBadge.inactive {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.AchievementBadge img {
  height: 100%;
  padding: 15%;
  vertical-align: top;
  width: 100%;
}

.AchievementDetails {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 9px;
  display: flex;
  margin: 10px 0;
  padding: 10px 20px;
  position: relative;
}

.AchievementDetails .AchievementBadge {
  border: 0;
  border-radius: 4px;
  min-width: 68px;
}

.AchievementDetails .info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
}

.AchievementDetails .info .title {
  color: #ffffff;
  display: none; /* TODO: [layout] put back when each badge has unique name */
  font-size: 18px;
  font-weight: 600;
}

.AchievementDetails .info .description {
  font-size: 15px;
}

.AchievementDetails .info .progress {
  background-color: #28008c;
  border: 1px solid #40297b;
  border-radius: 8px;
  display: none; /* TODO: [layout] put back when each badge has progress */
  height: 16px;
  margin-top: 5px;
  overflow: hidden;
}

.AchievementDetails .info .current {
  background-color: #7cf4fe;
  font-size: 15px;
  height: 100%;
  line-height: 14px;
  position: relative;
}

.AchievementDetails .info .current:not(.after) {
  color: #000000;
  text-align: center;
}

.AchievementDetails .info .current.after {
  color: #ffffff;
}

.AchievementDetails .info .current.after .value {
  display: table;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(calc(100% + 10px));
          transform: translateX(calc(100% + 10px));
}

.AchievementDetails .date {
  display: none; /* TODO: [layout] put back when storing badge earned date */
  font-size: 15px;
  position: absolute;
  right: 20px;
  top: 8px;
}

.AchievementDetails.active .info .progress {
  display: none;
}

.AchievementDetails.inactive .date {
  display: none;
}

@media (max-width: 639px) {
  .AchievementDetails {
    padding: 10px;
  }

  .AchievementDetails .info {
    padding-left: 10px;
  }

  .AchievementDetails .date {
    display: none;
  }
}

.AchievementSection.InfoBox {
  background-color: #1577b0;
}

.AchievementSection.InfoBox .boxHeader {
  background-color: #00588a;
}

.AchievementSection .singleBadge {
  cursor: pointer;
  display: table;
  margin: 0 auto;
}

.AchievementSection .badgesGroup {
  cursor: pointer;
  margin: 0 auto 2px;
  width: 272px;
}

.AchievementSection .moreBadges {
  display: inline-block;
  color: #140046;
  font-size: 40px;
  font-weight: bold;
  line-height: 68px;
  padding-left: 3px;
  transition: color 0.3s ease;
}

.AchievementSection .viewAll {
  color: #140046;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

.AchievementSection .badgesSingle .viewAll {
  align-self: center;
}

.AchievementSection .badgesContent.badges-0 {
  height: 100%;
  min-height: 94px;
  padding-top: 0;
}

.AchievementSection .badgesContent.badges-0 .viewAll {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.AchievementSection .badgesContent.badges-0 .viewAll br {
  display: none;
}

@media (max-width: 639px) {
  .AchievementSection .badgesSingle .viewAll {
    padding-top: 0;
  }
}

@media (min-width: 640px) {
  .AchievementSection .badgesContent {
    padding-top: 15px;
  }

  .AchievementSection .badgesGroup {
    margin-bottom: 0;
  }

  .AchievementSection .AchievementBadge {
    height: 92px;
    width: 92px;
  }

  .AchievementSection .moreBadges {
    line-height: 92px;
    padding-left: 15px;
  }

  .AchievementSection .viewAll {
    padding: 14px 0 10px;
  }
}

@media (min-width: 1024px) {
  .AchievementSection.InfoBox .boxContent {
    height: 188px;
  }

  .AchievementSection .viewAll:hover,
  .AchievementSection .badgesGroup:hover .viewAll,
  .AchievementSection .badgesGroup:hover .moreBadges {
    color: #ffffff;
  }

  .AchievementSection .AchievementBadge {
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .AchievementSection .AchievementBadge:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .AchievementSection.InfoBox .boxContent {
    padding-bottom: 26px;
  }
}

@media (min-width: 1366px) {
  .AchievementSection.InfoBox .boxContent {
    height: 218px;
  }

  .AchievementSection .badgesContent {
    margin: 0 auto;
    max-width: 540px;
    padding-top: 35px;
  }

  .AchievementSection .badgesGroup {
    margin-left: 14px;
    margin-right: 10px;
  }

  .AchievementSection .viewAll {
    padding: 20px 0;
  }
}

@media (min-width: 1680px) {
  .AchievementSection .badgesContent {
    max-width: 600px;
  }

  .AchievementSection .badgesGroup {
    margin-left: 30px;
    margin-right: 0;
  }

  .AchievementSection .viewAll {
    padding: 20px 0;
  }
}

.Modal {
  width: 100%;
  max-width: 800px;
  overflow: auto;
  max-height: 100%;
  z-index: 100;
  background: #151A1F;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2em;
  border-radius: 8px;
  border: 0;
  color: #b8baee;
  outline: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Modal h1 {
  font-weight: bold;
  font-size: 150%;
  margin: 0 0 15px;
  color: #b8baee;
}

.Modal a {
  color: #7cf4fe;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
}

.modal-close:hover {
  color: #7cf4fe;
}

.Modal .modal-content {
  background-color: #151A1F;
  border-color: #44626F;
  color: #b8baee;
  border-radius: 6px;
  border: 0;
}

.modal-header,
.modal-footer {
  border-color: #44626F;
}

.Notification.notification-toast {
  align-items: center;
  background-color: #22193d;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}

.Notification.notification-toast .icon {
  display: inline-block;
  height: 50px;
  margin-right: 15px;
  min-width: 50px;
  vertical-align: middle;
  width: 50px;
}

.Notification.notification-toast .icon img {
  width: 50px;
  height: 50px;
}

/* TODO: [layout] Change for SVG icon when available */
.Notification.notification-toast .icon .default {
  background-color: #410988;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 25px;
  font-size: 36px;
  font-weight: 100;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.Notification.notification-toast .notificationText {
  font-size: 16px;
}

.Notification.notification-toast .notificationText .title {
  color: #ffffff;
  line-height: 1.31;
  margin: 0;
}

.Notification.notification-toast .notificationText .message {
  color: #aca2cd;
}

/* Toast fadeIn animation */
@-webkit-keyframes notificationToastFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  80% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes notificationToastFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  80% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Notification.fadingIn.notification-toast {
  -webkit-animation: notificationToastFadeIn 0.3s ease;
          animation: notificationToastFadeIn 0.3s ease;
}

/* Toast fadeOut animation */
@-webkit-keyframes notificationToastFadeOut {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes notificationToastFadeOut {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.Notification.fadingOut.notification-toast {
  -webkit-animation: notificationToastFadeOut 0.3s ease;
          animation: notificationToastFadeOut 0.3s ease;
}

.Notification.notification-box {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.Notification.notification-box .background {
  background-color: #472e93;
  padding-top: 105px;
}

.Notification.notification-box .icon {
  background-color: #22193d;
  border-radius: 83px;
  box-shadow: 0 0 0 10px #472e93;
  height: 146px;
  left: 50%;
  padding: 20px;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 146px;
}

.Notification.notification-box .icon img {
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.Notification.notification-box .halo {
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Notification.notification-box .halo1 {
  -webkit-animation: notificationBoxIconHaloRotate 50s linear infinite;
          animation: notificationBoxIconHaloRotate 50s linear infinite;
  height: 196px;
  width: 196px;
}

.Notification.notification-box .halo2 {
  -webkit-animation: notificationBoxIconHaloRotate 30s linear infinite;
          animation: notificationBoxIconHaloRotate 30s linear infinite;
  height: 228px;
  width: 228px;
}

.Notification.notification-box .halo3 {
  -webkit-animation: notificationBoxIconHaloRotate 50s linear infinite;
          animation: notificationBoxIconHaloRotate 50s linear infinite;
  height: 276px;
  width: 276px;
}

.Notification.notification-box .title {
  background-color: #000000;
  border-radius: 28.5px;
  color: #00d5ae;
  display: inline-block;
  font-size: 23.8px;
  font-weight: 500;
  height: 50px;
  letter-spacing: 0.26px;
  line-height: 50px;
  margin: 25px 0 32px;
  text-align: center;
  width: 210px;
}

.Notification.notification-box .message {
  color: #ffffff;
  font-size: 37.8px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 44px;
  text-align: center;
}

/* Box animation */
@-webkit-keyframes notificationBoxSlide {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  30%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes notificationBoxSlide {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  30%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Notification.notification-box.fadingIn,
.Notification.notification-box.fadingOut {
  -webkit-animation: notificationBoxSlide 1.68s ease;
          animation: notificationBoxSlide 1.68s ease;
}

.Notification.notification-box.fadingOut {
  animation-direction: reverse;
}

/* Background animation */
@-webkit-keyframes notificationBoxBackground {
  0% {
    background-color: transparent;
  }
  30%,
  100% {
    background-color: #472e93;
  }
}
@keyframes notificationBoxBackground {
  0% {
    background-color: transparent;
  }
  30%,
  100% {
    background-color: #472e93;
  }
}

.Notification.notification-box.fadingIn .background,
.Notification.notification-box.fadingOut .background {
  -webkit-animation: notificationBoxBackground 1.68s ease;
          animation: notificationBoxBackground 1.68s ease;
}

.Notification.notification-box.fadingOut .background {
  animation-direction: reverse;
}

/* Halo circles animation */
@-webkit-keyframes notificationBoxIconHaloRotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes notificationBoxIconHaloRotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes notificationBoxMessageHalo1 {
  0%,
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes notificationBoxMessageHalo1 {
  0%,
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes notificationBoxMessageHalo2 {
  0%,
  59% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes notificationBoxMessageHalo2 {
  0%,
  59% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes notificationBoxMessageHalo3 {
  0%,
  62% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes notificationBoxMessageHalo3 {
  0%,
  62% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Notification.notification-box.fadingIn .halo1 {
  -webkit-animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo1 1.68s ease;
          animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo1 1.68s ease;
}

.Notification.notification-box.fadingIn .halo2 {
  -webkit-animation: notificationBoxIconHaloRotate 30s linear infinite,
    notificationBoxMessageHalo2 1.68s ease;
          animation: notificationBoxIconHaloRotate 30s linear infinite,
    notificationBoxMessageHalo2 1.68s ease;
}

.Notification.notification-box.fadingIn .halo3 {
  -webkit-animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo3 1.68s ease;
          animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo3 1.68s ease;
}

.Notification.notification-box.fadingOut .halo1 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo1 1.68s ease reverse;
}

.Notification.notification-box.fadingOut .halo2 {
  animation: notificationBoxIconHaloRotate 30s linear infinite,
    notificationBoxMessageHalo2 1.68s ease reverse;
}

.Notification.notification-box.fadingOut .halo3 {
  animation: notificationBoxIconHaloRotate 50s linear infinite,
    notificationBoxMessageHalo3 1.68s ease reverse;
}

/* Icon animation */
@-webkit-keyframes notificationBoxIcon {
  0% {
    box-shadow: 0 0 0 10px transparent;
    opacity: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  12% {
    opacity: 1;
  }
  24% {
    box-shadow: 0 0 0 10px transparent;
  }
  36% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  48%,
  100% {
    box-shadow: 0 0 0 10px #472e93;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@keyframes notificationBoxIcon {
  0% {
    box-shadow: 0 0 0 10px transparent;
    opacity: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  12% {
    opacity: 1;
  }
  24% {
    box-shadow: 0 0 0 10px transparent;
  }
  36% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  48%,
  100% {
    box-shadow: 0 0 0 10px #472e93;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.Notification.notification-box.fadingIn .icon,
.Notification.notification-box.fadingOut .icon {
  -webkit-animation: notificationBoxIcon 1.68s ease;
          animation: notificationBoxIcon 1.68s ease;
}

.Notification.notification-box.fadingOut .icon {
  animation-direction: reverse;
}

/* Title animation */
@-webkit-keyframes notificationBoxTitle {
  0%,
  55% {
    color: transparent;
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  65% {
    color: transparent;
  }
  74%,
  100% {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    color: #00d5ae;
  }
}
@keyframes notificationBoxTitle {
  0%,
  55% {
    color: transparent;
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  65% {
    color: transparent;
  }
  74%,
  100% {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    color: #00d5ae;
  }
}

.Notification.notification-box.fadingIn .title,
.Notification.notification-box.fadingOut .title {
  -webkit-animation: notificationBoxTitle 1.68s ease;
          animation: notificationBoxTitle 1.68s ease;
}

.Notification.notification-box.fadingOut .title {
  animation-direction: reverse;
}

/* Message animation */
@-webkit-keyframes notificationBoxMessage {
  0%,
  33% {
    opacity: 0;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
  57%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes notificationBoxMessage {
  0%,
  33% {
    opacity: 0;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
  57%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Notification.notification-box.fadingIn .message,
.Notification.notification-box.fadingOut .message {
  -webkit-animation: notificationBoxMessage 1.68s ease;
          animation: notificationBoxMessage 1.68s ease;
}

.Notification.notification-box.fadingOut .message {
  animation-direction: reverse;
}

/* Responsive layout */
@media (max-width: 1023px) {
  .Notification.notification-box .background {
    left: 50%;
    -webkit-transform: scale(0.8) translateX(-12.5%);
            transform: scale(0.8) translateX(-12.5%);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    width: 125%;
  }
}

@media (max-width: 639px) {
  .Notification.notification-box .background {
    left: 50%;
    -webkit-transform: scale(0.5) translateX(-50%);
            transform: scale(0.5) translateX(-50%);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    width: 200%;
  }
}

.NotificationContainer {
  position: fixed;
  right: 400px;
  top: 45px;
  width: 330px;
  z-index: 150;
}

@media (max-width: 1365px) {
  .NotificationContainer {
    top: 16px;
  }
}

@media (max-width: 1023px) {
  .NotificationContainer {
    right: 40px;
    top: 80px;
  }
}

@media (max-width: 639px) {
  .NotificationContainer {
    right: 5px;
    top: 4px;
    width: calc(100% - 10px);
  }

  .NotificationContainer .NotificationToast {
    padding: 5px;
  }
}

.Dashboard {
  background-color: #140046;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 15px;
}

.Dashboard .Logout {
  color: #ffffff;
}

.Dashboard .rankingGroupSection {
  margin-top: 10px;
}

.Dashboard .hidden-version-text {
  color: transparent;
  cursor: default;
}

.DashboardTooltip {
  background-color: uiGrayDarker !important;
  color: #b8baee !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.Dashboard .container {
  padding: 0 16px 0 11px;
}

@media (min-width: 640px) {
  .Dashboard .NotificationContainer {
    right: 30px;
  }
}

@media (min-width: 1024px) {
  .Dashboard .NotificationContainer {
    right: 50px;
  }

  .Dashboard .container {
    padding: 0 35px;
  }
}

@media (min-width: 1366px) {
  .Dashboard .NotificationContainer {
    right: 75px;
  }

  .Dashboard .rankingGroupSection {
    margin-top: 65px;
  }

  .Dashboard .container {
    padding: 0 60px;
  }
}

@media (min-width: 1680px) {
  .Dashboard .NotificationContainer {
    right: 95px;
  }

  .Dashboard .rankingGroupSection {
    margin-top: 0px;
  }

  .Dashboard .container {
    padding: 0 80px;
  }
}

.scroll {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 100%;
  z-index: 2;
}

.scroll .scroll-holder {
  position: relative;
  height: 100%;
}

.scroll > .scroll-holder > .imageSlider {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  position: absolute;
  width: 100%; /* Note: This width does not have an effect. */
  margin-left: 10px;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
}

input.imageSlider:focus {
  outline: none;
}

input.imageSlider::-moz-focus-outer {
  border: none;
}

input.imageSlider::-webkit-slider-thumb {
  background: #472e93;
  border: 0;
  border-radius: 10px;
  cursor: -webkit-grab;
  height: 20px;
  width: 45px;
  -webkit-appearance: none;
}

input.imageSlider::-webkit-slider-thumb:active {
  background: #7cf4fe;
  cursor: -webkit-grabbing;
}

input[type='range']::-ms-thumb {
  cursor: -webkit-grab;
  background: #472e93;
  -webkit-appearance: none;
  width: 45px;
  height: 20px;
  border-radius: 10px;
  border-color: transparent;
}

input[type='range']::-ms-thumb:active {
  background: #7cf4fe;
  cursor: -webkit-grabbing;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 65px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: transparent;
  border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
  background: transparent;
  border-radius: 10px;
}

.ViewportOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.ViewportOverlay .overlay-element {
  position: absolute;
  color: lightblue;
  text-shadow: 1px 1px black;
}

.ViewportOverlay .overlay-element > span {
  display: block;
}

.ViewportOverlay .top-right {
  top: 10px;
  right: 25px; /* room for scrollbar */
}

.ViewportOverlay .top-left {
  top: 10px;
  left: 10px;
}

.ViewportOverlay .bottom-right {
  bottom: 10px;
  right: 30px;
}

.ViewportOverlay .bottom-left {
  bottom: 10px;
  left: 10px;
}

.ToolContextMenu {
  position: absolute;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  z-index: 1000;
  display: block;
  width: 160px;
}

.ToolContextMenu > ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.ToolContextMenu > ul > li > button {
  padding: 10px;
  font-size: 14px;
  border: none;
  color: #516873;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  background: none;
}

.ToolContextMenu > ul > li > button:hover {
  color: #16202B;
}

.ToolContextMenu > ul > li > button:active {
  color: #7cf4fe;
}

.select-tree-root {
  display: inline-block;
  text-align: left;
  text-align: initial;
  width: 320px;
  font-size: 14px;
  line-height: 26px;
}

.select-tree-root .tree-content {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #ffffff;
}

.select-tree-root .tree-header {
  background-color: #c0c0c0;
  border-bottom: 1px solid #c4c4c4;
  color: #303030;
  padding: 5px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  justify-content: center;
  height: 37px;
}

.select-tree-root .tree-header .wrapperText {
  font-weight: bold;
}

.select-tree-root .wrapperText {
  white-space: nowrap;
  cursor: pointer;
}

.select-tree-root .breadcrumb .wrapperLabel {
  cursor: pointer;
}

.select-tree-root .breadcrumb .backIcon {
  padding-right: 10px;
}

.select-tree-root .breadcrumb .backIcon svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  color: #457fb0;
}

.select-tree-root .tree-options {
  overflow: auto;
}

.select-tree-root .tree-node,
.select-tree-root .tree-leaf {
  cursor: pointer;
  display: block;
  overflow: hidden;
  font-weight: normal;
  box-shadow: 0 0 0 200px transparent;
  line-height: 26px;
}

.select-tree-root .radioLabel .radioInput {
  display: none;
}

.labellingComponent {
  position: absolute;
  text-align: center;
  z-index: 300;
}

.labellingComponent .selectedLabel,
.labellingComponent .selectedDescription {
  padding: 5px;
  background-color: white;
  width: 150px;
}

.labellingComponent .addLabelButton {
  color: #000000;
  background-color: #20a5d6;
  border: 2px solid #44626f;
  border-radius: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  opacity: 1;
  padding: 0 14px;
  transition: opacity 0.3s ease;
  outline: none;
  cursor: pointer;
}

.labellingComponent .commonButton {
  border: 1px solid #44626f;
  color: #ffffff;
  background-color: #000000;
  border-radius: 16px;
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  padding: 0 12px;
  margin: 10px 5px 0 0;
  outline: none;
  cursor: pointer;
}

.labellingComponent .textArea {
  background-color: #ffffff;
  line-height: 46px;
  max-width: 320px;
  min-width: 260px;
  padding: 0 12px;
  margin: 0 auto;
  display: inline-block;
}

.labellingComponent .checkIconWrapper {
  display: inline-block;
  background-color: #79f9fe;
  border-radius: 46px;
  width: 46px;
  height: 46px;
  margin-right: 10px;
  vertical-align: bottom;
  cursor: pointer;
}

.labellingComponent .checkIcon {
  width: 20px;
  height: 20px;
  margin: 13px;
  fill: black;
}

.labellingComponent .commonButtons {
  text-align: center;
  margin-left: 46px;
}

.labelling-appear {
  opacity: 0;
}
.labelling-appear.labelling-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.labelling-exit {
  opacity: 1;
}
.labelling-exit.labelling-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.labelling-exit-done {
  opacity: 0;
}

.CornerstoneViewport {
  height: 100%;
  position: relative;
  width: 100%;
}

.ToolbarButton {
  color: #9CCEF9;
  cursor: pointer;
  height: 48px;
  min-width: 48px;
  padding: 0 5px;
  text-align: center;
}

.ToolbarButton svg {
  fill: #9CCEF9;
  -webkit-filter: drop-shadow(1px 1px 1px black);
          filter: drop-shadow(1px 1px 1px black);
  height: 30px;
  stroke: #9CCEF9;
  width: 30px;
}

.ToolbarButton:hover {
  color: #ffffff;
}

.ToolbarButton:hover svg {
  fill: #ffffff;
  stroke: #ffffff;
}

.ToolbarButton.active,
.ToolbarButton:active {
  color: #7cf4fe;
}

.ToolbarButton.active svg,
.ToolbarButton:active svg {
  fill: #7cf4fe;
  stroke: #7cf4fe;
}

.ToolbarButton span {
  display: block;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.ToolbarSection {
  border-radius: 8px;
  display: flex;
  margin-top: 17px;
  z-index: 100;
}

.ToolbarSection .wlPresets,
.ToolbarSection .tools {
  display: flex;
}

.ToolbarSection .presetSelected {
  pointer-events: none;
}

@media (min-width: 1366px) {
  .ToolbarSection {
    flex-direction: row-reverse;
  }

  .ToolbarSection .presetSelected {
    display: none;
  }
}

@media (max-width: 1365px) {
  .ToolbarSection .presetSelected {
    bottom: -22px;
    color: #9CCEF9;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    white-space: nowrap;
  }

  .ToolbarSection .tools svg,
  .ToolbarSection .wlPresets svg {
    height: 20px;
    width: 20px;
  }

  .ToolbarSection .ToolbarButton {
    display: inline-block;
    height: auto;
    min-width: auto;
    padding: 8px 7px;
  }

  .ToolbarSection .tools .caption,
  .ToolbarSection .wlPresets .caption {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  .ToolbarSection .wlPresets {
    border-radius: 8px;
    border: solid 1px #4a4d7a;
    display: table;
    padding: 0 4px;
    position: relative;
    white-space: nowrap;
  }
}

@media (max-width: 1023px) {
  .ToolbarSection {
    margin-top: 0;
    width: 100%;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    border-right: 2px solid black;
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
  }

  .ToolbarSection .ToolbarButton {
    align-items: center;
    min-width: 34px;
    min-height: 34px;
    padding: 0;
    position: relative;
    width: auto;
  }

  .ToolbarSection .ToolbarButton svg {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .ToolbarSection .presetSelected {
    left: 8px;
    bottom: -24px;
    text-shadow: 1px 1px #000000;
  }

  .ToolbarSection .tool-Pan,
  .ToolbarSection .tool-Zoom,
  .ToolbarSection .tool-StackScroll,
  .ToolbarSection .tool-reset {
    display: none !important;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .ToolbarSection .ToolbarButton {
    display: flex;
    flex-grow: 1;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    flex-grow: 1;
  }

  .ToolbarSection .wlPresets {
    flex-basis: 66%;
  }

  .ToolbarSection .tools {
    flex-basis: 34%;
  }

  .ToolbarSection .wlPresets .ToolbarButton {
    min-width: 25%;
  }

  .ToolbarSection .tools .ToolbarButton {
    min-width: 50%;
  }

  .ToolbarSection .ToolbarButton svg {
    max-height: 30px;
    max-width: 30px;
    min-height: 20px;
    min-width: 20px;
    width: calc(100% - 16px);
  }
}

@media (max-width: 639px) {
  .ToolbarSection {
    display: flex;
    padding-top: 5px;
    width: 100%;
  }

  .ToolbarSection .wlPresets,
  .ToolbarSection .tools {
    border: 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    padding: 0;
    white-space: nowrap;
  }

  .ToolbarSection .ToolbarButton {
    height: 40px;
    max-width: 40px;
    min-width: 36px;
    width: auto;
  }

  .ToolbarSection .ToolbarButton svg {
    width: calc(100% - 12px);
  }
}

.CaseFeedback {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.CaseFeedback .feedback-button {
  background-color: #15125e;
  border-radius: 9px 0 0 9px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 43px;
  line-height: 43px;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 131px;
}

.CaseFeedback .feedback-button:disabled {
  background-color: #140046;
  color: #8684bf;
  cursor: not-allowed;
}

.CaseFeedback .feedback-button:not(:disabled):hover {
  background-color: #140046;
}

.CaseFeedback .feedback-button:not(:disabled):active,
.CaseFeedback .feedback-button[active='true'] {
  background-color: #140046;
  position: relative;
  z-index: 30;
}

.CaseFeedback .arrow {
  display: inline-block;
  height: 0;
  margin: 0 6px;
  position: relative;
  right: 0px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  width: 0;
  vertical-align: middle;
}

.CaseFeedback .arrow:before {
  border-color: transparent;
  border-style: solid;
  border-top-color: #ffffff;
  border-width: 5px;
  content: '';
  height: 0;
  left: 0;
  margin-top: -2.5px;
  position: absolute;
  top: 0;
  width: 0;
}

.CaseFeedback .feedback-options {
  display: block;
  position: absolute;
  z-index: 50;
  background-color: #1e1d32;
  border-radius: 9px;
  padding: 6px 16px 16px 16px;
  width: 244px;
}

.CaseFeedback .feedback-options:before {
  content: '';
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 12px;
  left: 70px;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.CaseFeedback .feedback-options ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 3px;
}

.CaseFeedback .feedback-options li {
  padding: 1.5px 0;
  list-style: none;
  color: white;
}

.CaseFeedback .feedback-options .button {
  display: block;
  width: 100%;
  margin-top: 8px;
  height: 40px;
}

.CaseFeedback .button {
  display: none;
}

.caseFeedbackOptionsBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;
}

/* Responsive properties */
.CaseFeedback .feedback-options {
  bottom: calc(43px + 12px);
}

.CaseFeedback .feedback-options:before {
  bottom: calc(12px * -2);
  border-top-color: #1e1d32;
}

.CaseFeedback .arrow {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}

.CaseFeedback .feedback-button[active='true'] .arrow {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

@media (min-width: 1024px) {
  .CaseFeedback .feedback-options {
    bottom: auto;
    top: calc(43px + 12px);
  }

  .CaseFeedback .feedback-options:before {
    border-bottom-color: #1e1d32;
    border-top-color: transparent;
    bottom: auto;
    top: calc(12px * -2);
  }

  .CaseFeedback .arrow {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }

  .CaseFeedback .feedback-button[active='true'] .arrow {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
}

.Checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto;
  transition: all 0.3s ease;
}

.Checkbox input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease;
}

.control-indicator {
  position: absolute;
  top: 6px;
  left: 0;
  height: 18px;
  width: 18px;
  background: transparent;
  border: 2px solid white;
  border-radius: 3px;
  margin-right: 20px;
  transition: all 0.3s ease;
}

.Checkbox:hover input ~ .control-indicator {
  background: rgba(255, 255, 255, 0.1);
}

.Checkbox input:checked ~ .control-indicator {
  background: #ffffff;
}

.Checkbox:hover input:not([disabled]):checked ~ .control-indicator,
.Checkbox input:checked:focus ~ .control-indicator {
  background: #0e6647d;
}

.Checkbox input:disabled ~ .control-indicator {
  background: #e6e6e6;
  opacity: 0;
  pointer-events: none;
}

.control-indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.Checkbox input:checked ~ .control-indicator:after {
  display: block;
}

.Checkbox .control-indicator:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box;
}

.Checkbox input:disabled ~ .control-indicator:after {
  border-color: #7b7b7b;
}

.CaseControlButtons {
  white-space: nowrap;
}

.CaseControlButtons .CompleteButtonWrapper {
  display: inline-block;
  vertical-align: top;
}

.CaseControlButtons button {
  background-color: #00d5ae;
  border: 0;
  border-radius: 9px;
  color: #100e44;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 43px;
  line-height: 43px;
  opacity: 1;
  outline: none;
  padding: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  vertical-align: middle;
  width: 145px;
}

.CaseControlButtons button.complete-case {
  border-radius: 0 9px 9px 0;
}

.CaseControlButtons button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.CaseControlButtons button:not(:disabled):hover {
  background-color: white;
}

.CaseControlButtons .btn-group {
  display: inline-block;
  margin-left: 15px;
}

.CaseProgress {
  margin-bottom: 17px;
}

.CaseProgress .sessionCount {
  background-color: #472e93;
  height: 68px;
  padding: 9px 11px;
  position: relative;
  width: 150px;
}

.CaseProgress .sessionCount .value {
  color: #7cf4fe;
  font-size: 52px;
  font-weight: 600;
  line-height: 50px;
}

.CaseProgress > *:first-child {
  border-radius: 9px 0 0 9px;
}

.CaseProgress > *:last-child {
  border-radius: 0 9px 9px 0;
}

.CaseProgress .icon {
  background-color: #3b2d66;
  border-left: 2px solid #000000;
  cursor: pointer;
  height: 68px;
  text-align: center;
  width: 90px;
}

.CaseProgress .icon svg {
  display: inline-block;
  fill: #7cf4fe;
  height: 24px;
  margin: 12px 0 7px;
  stroke: #7cf4fe;
  width: 24px;
}

.CaseProgress .icon div {
  color: #7cf4fe;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
}

/* Increment label */

.CaseProgress .sessionCount .increment {
  background-color: #00d5ae;
  border: 3px solid #000000;
  border-radius: 24px;
  color: #15125e;
  font-size: 21px;
  font-weight: 600;
  height: 35px;
  line-height: 29px;
  opacity: 0;
  padding: 0 12px;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-60%, -60%);
          transform: translate(-60%, -60%);
  transition: opacity 0.3s linear;
  left: 0;
}

.CaseProgress .sessionCount .increment.visible {
  opacity: 1;
}

.CaseProgress .sessionCount .increment.slideIn {
  -webkit-animation: slideIn 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: slideIn 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.CaseProgress .sessionCount .increment.shift {
  -webkit-animation: shiftInOut 0.3s ease-in;
          animation: shiftInOut 0.3s ease-in;
}

.CaseProgress .sessionCount .increment.slideOut {
  -webkit-animation: slideOut 0.3s ease;
          animation: slideOut 0.3s ease;
}

/* Total Score */

.CaseProgress .totalScore {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
}

.CaseProgress .ProgressSection .progressBarContainer {
  display: none;
}

.CaseProgress .ProgressSection .numberCases {
  display: flex;
  flex-direction: column;
}

.CaseProgress .ProgressSection .rankBadgeContainer {
  margin-top: -15px;
  position: relative;
}

.CaseProgress .ProgressSection .rankBadgeContainer:before {
  background-color: #000000;
  border: solid 1px #6c57ae;
  border-radius: 6.5px;
  bottom: 4px;
  color: #ffffff;
  content: 'TOTAL';
  display: block;
  font-size: 9px;
  font-weight: 600;
  height: 15px;
  left: 50%;
  line-height: 13px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 41px;
}

.CaseProgress .ProgressSection .rankBadge:hover {
  -webkit-transform: none;
          transform: none;
}

.CaseProgress .ProgressSection .rankBadge img {
  height: 64px;
  vertical-align: top;
  width: 64px;
}

.CaseProgress .ProgressSection .currentPoints {
  margin: -9px 0 0;
  text-align: center;
}

.CaseProgress .ProgressSection .currentPoints .value {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.CaseProgress .ProgressSection .currentPoints .value .maxWidth {
  display: none;
}

.CaseProgress .ProgressSection .currentPoints .value .visible {
  position: static;
  position: initial;
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translate(-60%, 33px);
            transform: translate(-60%, 33px);
  }
  100% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translate(-60%, 33px);
            transform: translate(-60%, 33px);
  }
  100% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
}

@-webkit-keyframes slideOut {
  0% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
  50% {
    -webkit-transform: translate(-60%, 20%);
            transform: translate(-60%, 20%);
  }
  100% {
    -webkit-transform: translate(-60%, -200%);
            transform: translate(-60%, -200%);
  }
}

@keyframes slideOut {
  0% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
  50% {
    -webkit-transform: translate(-60%, 20%);
            transform: translate(-60%, 20%);
  }
  100% {
    -webkit-transform: translate(-60%, -200%);
            transform: translate(-60%, -200%);
  }
}

@-webkit-keyframes shiftInOut {
  0% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
  50% {
    -webkit-transform: translate(-80%, -60%);
            transform: translate(-80%, -60%);
  }
  100% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
}

@keyframes shiftInOut {
  0% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
  50% {
    -webkit-transform: translate(-80%, -60%);
            transform: translate(-80%, -60%);
  }
  100% {
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);
  }
}

@media (min-width: 1024px) {
  .CaseProgress .sessionCount {
    width: 180px;
  }

  .CaseProgress .totalScore {
    display: block;
  }

  .CaseProgress .icon svg {
    transition: fill 0.3s ease, stroke 0.3s ease;
  }

  .CaseProgress .icon div {
    transition: color 0.3s ease;
  }

  .CaseProgress .icon {
    transition: background-color 0.3s ease;
  }

  .CaseProgress .icon:hover {
    background-color: #140046;
  }

  .CaseProgress .icon:hover svg {
    fill: #ffffff;
    stroke: #ffffff;
  }

  .CaseProgress .icon:hover div {
    color: #ffffff;
  }

  .CaseProgress .icon.endSession {
    border-radius: 0 9px 9px 0;
  }

  .CaseProgress .icon.instructions {
    align-items: center;
    background: none;
    border: 0;
    border-radius: 0;
    bottom: -14px;
    display: flex;
    margin: 0;
    position: absolute;
    right: 14px;
    transition: color 0.3s ease;
    width: auto;
  }

  .CaseProgress .icon.instructions svg {
    margin: 0 8px 0 0;
  }
}

.MeasurementControl {
  display: table;
  position: relative;
}

.MeasurementControl .controls {
  border-radius: 8px;
  border: solid 1px #4a4d7a;
  background-color: #000000;
  padding: 10px 0;
  height: 45px;
}

.MeasurementControl .controls .number {
  color: #7cf4fe;
  font-size: 20px;
  line-height: 23px;
  margin: 0 10px;
}

.MeasurementControl .lesions {
  bottom: -20px;
  color: #7cf4fe;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.MeasurementControl.disabled .label {
  display: none;
}

@media (min-width: 1024px) {
  .MeasurementControl .label {
    background-color: #00d5ae;
    border: 0;
    border-radius: 9px;
    color: #100e44;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    height: 38px;
    line-height: 38px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -100%;
    text-align: center;
    top: 13px;
    transition: background-color 0.3s ease;
    width: 100px;
  }

  .MeasurementControl .label:hover {
    background-color: #ffffff;
  }

  .MeasurementControl .magnify {
    display: none;
  }

  .MeasurementControl .controls .left-arrow,
  .MeasurementControl .controls .right-arrow {
    border: 7px solid #7cf4fe;
    border-top-color: transparent;
    border-bottom-color: transparent;
    display: inline-block;
    width: 0;
    height: 0;
  }

  .MeasurementControl .controls .arrow-container {
    cursor: pointer;
    transition: 0.15s all ease;
  }

  .MeasurementControl .controls .arrow-container .arrow {
    transition: 0.15s all ease;
  }

  .MeasurementControl.disabled .arrow-container {
    background-color: #262548;
    cursor: not-allowed;
  }

  .MeasurementControl.disabled .arrow-container > .arrow {
    border: 7px solid #3a5874;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .MeasurementControl:not(.disabled) .arrow-container:hover {
    background-color: #0f373b;
  }

  .MeasurementControl:not(.disabled) .arrow-container:active {
    background-color: #7cf4fe;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .arrow {
    border: 7px solid #000000;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .MeasurementControl.disabled .arrow-container > .left-arrow {
    border-left: 0px;
  }

  .MeasurementControl.disabled .arrow-container > .right-arrow {
    border-right: 0px;
  }

  .MeasurementControl .controls .right-arrow {
    border-right: 0px;
    margin-left: 2px;
  }

  .MeasurementControl .controls .left-arrow {
    border-left: 0px;
    margin-right: 2px;
  }

  .MeasurementControl .controls .arrow-container {
    background-color: #262548;
    border-radius: 50%;
    display: inline-block;
    height: 22px;
    margin: 0 10px;
    padding: 3px 0px 0px 6px;
    width: 22px;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .left-arrow {
    border-left: 0px;
  }

  .MeasurementControl:not(.disabled)
    .arrow-container:active
    > .right-arrow {
    border-right: 0px;
  }
}

@media (max-width: 1023px) {
  .MeasurementControl {
    display: flex;
    white-space: nowrap;
    width: 100%;
  }

  .MeasurementControl .controls,
  .MeasurementControl .label,
  .MeasurementControl .magnify {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid #16daac;
    border-radius: 9px;
    color: #ffffff;
    display: block;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 600;
    height: 38px;
    line-height: 38px;
    margin: 0 2%;
    overflow: hidden;
    padding: 0;
    text-align: center;
  }

  .MeasurementControl .label,
  .MeasurementControl .magnify {
    padding: 0 8px;
  }

  .MeasurementControl .magnify.active {
    color: #7cf4fe;
  }

  .MeasurementControl .controls {
    display: flex;
  }

  .MeasurementControl .controls .number {
    color: #a8a8a8;
    flex-grow: 1;
    font-size: 25px;
    font-weight: normal;
    line-height: 38px;
    margin: 0;
    text-align: center;
    vertical-align: top;
  }

  .MeasurementControl .controls > span {
    background-color: transparent !important;
    display: inline-block;
    height: 100%;
    padding: 0 4px;
    position: relative;
    min-width: 38px;
  }

  .MeasurementControl .controls > span:not(:last-child) {
    border-right: 1px solid #16daac;
  }

  .MeasurementControl .controls .arrow-container {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .MeasurementControl .controls .arrow-container > .arrow {
    border: 2px solid #ffffff;
    border-left: 0;
    border-top: 0;
    display: block;
    height: 12px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    width: 12px;
  }

  .MeasurementControl .controls .arrow-container > .arrow.left-arrow {
    -webkit-transform: translate(calc(-50% + 3px), -50%) rotate(135deg);
            transform: translate(calc(-50% + 3px), -50%) rotate(135deg);
  }

  .MeasurementControl .controls .arrow-container > .arrow.right-arrow {
    -webkit-transform: translate(calc(-50% - 3px), -50%) rotate(-45deg);
            transform: translate(calc(-50% - 3px), -50%) rotate(-45deg);
  }

  .MeasurementControl.disabled {
    display: none;
  }
}

.Viewer {
  background-color: #000000;
  height: 100%;
  width: 100%;
}

.Viewer .LoadingIndicator {
  background: #000000 !important;
}

.Viewer .viewport-section {
  bottom: 0;
  display: flex;
  height: calc(100% - 122px);
  left: 0;
  position: fixed;
  width: 100%;
}

.Viewer .viewport-section .viewport {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  width: 100%;
}

.Viewer .ViewportControl {
  left: 16px;
  position: fixed;
  top: 40px;
}

.Viewer .MeasurementControl {
  margin-left: 20px;
  padding-top: 10px;
}

.Viewer .SessionControl {
  position: fixed;
  right: 22px;
  top: 44px;
}

.Viewer .Logo {
  font-size: 15px;
  left: 27px;
  line-height: 18px;
  position: fixed;
  top: 19px;
}

.Viewer .ViewportOverlay {
  pointer-events: none;
}

.Viewer .ViewportOverlay .top-left {
  top: 10px;
}

.Viewer .ViewportOverlay .bottom-left,
.Viewer .ViewportOverlay .top-left {
  left: 27px;
}

.Viewer .ViewportOverlay .bottom-right {
  right: 32px;
}

.Viewer .magnifyTool {
  border: 4px #ffffff solid;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #1e1e1e;
  cursor: none;
  display: none;
  /* TODO: [layout]
   * The `overflow: hidden` declaration is not working for android when using 50% radius.
   * This is making the magnifying glass to be shaped as a square.
   * See: https://stackoverflow.com/questions/21584679
   */
  overflow: hidden;
  z-index: 100;
}

@media (max-width: 1365px) {
  .Viewer .viewport-section {
    height: calc(100% - 100px);
  }

  .Viewer .Logo {
    display: none;
  }

  .Viewer .ViewportControl {
    top: 18px;
  }

  .Viewer .SessionControl {
    top: 22px;
  }
}

@media (max-width: 1023px) {
  .Viewer .viewport-section {
    height: calc(100% - 68px);
  }

  .Viewer .ViewportOverlay .overlay-element:not(.top-left) {
    display: none;
  }

  .Viewer .ViewportOverlay .overlay-element.top-left {
    left: unset;
    right: 40px;
    top: 4px;
  }

  .Viewer
    .ViewportOverlay
    .overlay-element.top-left
    > span:not(.loadingProgress) {
    display: none;
  }

  .Viewer .SessionControl {
    right: 0;
    top: 0;
  }

  .Viewer .SessionControl .CaseProgress > * {
    border-radius: 0;
  }

  .Viewer .SessionControl .CaseProgress .increment {
    background-color: transparent;
    border-color: transparent;
    color: #ffffff;
    top: 15px;
    left: auto;
    right: -40px;
  }

  .Viewer .SessionControl .CaseControlButtons {
    bottom: 8px;
    position: fixed;
    right: 32px;
  }

  .Viewer .ViewportControl {
    background-color: #15125e;
    height: 68px;
    left: 0;
    padding-right: 416px;
    top: 0;
    width: 100%;
  }

  .Viewer .MeasurementControl {
    bottom: 8px;
    padding: 0;
    padding-right: 360px;
    position: fixed;
    left: 0;
  }

  .Viewer .MeasurementControl .lesions {
    display: none;
  }

  .Viewer .labellingComponent {
    bottom: 52px;
    top: unset !important;
  }
}

@media (max-width: 639px) {
  .Viewer .viewport-section {
    height: calc(100% - 48px);
    position: relative;
    top: 48px;
    z-index: auto;
  }

  .Viewer .ViewportOverlay .overlay-element.top-left {
    bottom: 50px;
    left: 12px;
    top: auto;
  }

  .Viewer .SessionControl {
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons {
    bottom: 0;
    display: flex;
    padding: 0 12px 8px;
    right: 0;
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons > div {
    flex-grow: 1;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button,
  .Viewer .SessionControl .CaseControlButtons button.complete-case {
    font-size: 15px;
    height: 38px;
    line-height: 38px;
    width: 100%;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button {
    border-radius: 9px 0 0 9px;
  }

  .Viewer .SessionControl .CaseControlButtons .feedback-button .arrow {
    display: none;
  }

  .Viewer .SessionControl .CaseControlButtons button.complete-case {
    border-radius: 0 9px 9px 0;
  }

  .Viewer .SessionControl .CaseControlButtons .CaseFeedback .feedback-options {
    left: 0;
  }

  .Viewer
    .SessionControl
    .CaseControlButtons
    .CaseFeedback
    .feedback-options:before {
    left: unset;
    right: 116px;
  }

  .Viewer .SessionControl .CaseProgress {
    margin: 0;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount {
    flex-grow: 1;
    min-width: 100px;
    width: auto;
  }

  .Viewer .SessionControl .CaseProgress .icon > div {
    font-size: 15px;
    font-weight: 600;
    margin: 0 10px 0 0;
    text-align: left;
  }

  .Viewer .SessionControl .CaseProgress .icon > svg {
    min-width: 24px;
    margin: 0 10px;
  }

  .Viewer .SessionControl .CaseProgress .icon {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: auto;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount,
  .Viewer .SessionControl .CaseProgress .icon {
    height: 48px;
  }

  .Viewer .SessionControl .CaseProgress .sessionCount .value {
    font-size: 40px;
    line-height: 30px;
  }

  .Viewer .MeasurementControl {
    bottom: auto;
    left: 0;
    margin: 0;
    padding-right: 32px;
    top: 100px;
  }

  .Viewer .ViewportControl {
    background-color: transparent;
    flex-wrap: wrap;
    height: auto;
    padding: 0;
    padding-right: 28px;
    top: 48px;
    width: 100%;
  }

  .Viewer .ToolbarSection .presetSelected {
    display: none;
  }

  .Viewer .labellingComponent {
    bottom: 50px;
    left: 6px !important;
    position: fixed;
    right: 6px !important;
    top: unset !important;
    white-space: nowrap;
    width: auto;
  }

  .Viewer .select-tree {
    width: 100%;
  }

  .Viewer .addLabelButton {
    display: none;
  }
}

@media (max-width: 400px) {
  .Viewer .SessionControl .CaseProgress .icon.instructions > div {
    display: none;
  }
}

@media (max-width: 360px) {
  .Viewer .SessionControl .CaseProgress .icon > div {
    display: none;
  }
}

.SessionSummary {
  background-color: #140046;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 15px;
  position: relative;
}

.SessionSummary .Logo {
  left: 27px;
  position: absolute;
  top: 19px;
}

.SessionSummary .pageHeader {
  display: flex;
  height: 46px;
  margin: 127px 0 40px;
  padding-left: 25px;
}

.SessionSummary .pageHeader h1 {
  color: #7cf4fe;
  flex-grow: 1;
  font-size: 34px;
  font-style: italic;
  font-weight: 500;
  line-height: 46px;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.SessionSummary .controlButtons {
  white-space: nowrap;
}

.SessionSummary .controlButtons button {
  border: 0;
  border-radius: 9px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 46px;
  line-height: 46px;
  margin-left: 21px;
  margin-right: 8px;
  padding: 0;
  text-align: center;
}

.SessionSummary .controlButtons .logout {
  background-color: #2a0e6f;
  border: 1px solid #ffffff;
  color: #ffffff;
  line-height: 44px;
  width: 85px;
}

.SessionSummary .controlButtons .dashboard {
  background-color: #00d5ae;
  color: #100e44;
  width: 181px;
}

.SessionSummary .cardSection {
  background-color: #472e93;
  border-radius: 9px;
  box-shadow: 13px 13px 0 0 #030628;
  margin-top: 20px;
  padding: 17px 23px;
}

.SessionSummary .cardSection h2 {
  color: #ffffff;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  line-height: 34px;
  margin: 0;
  text-transform: uppercase;
}

.SessionSummary .sessionTotalValue {
  color: #7cf4fe;
  font-size: 102px;
  font-weight: 600;
  margin-top: -38px;
  text-align: center;
}

.SessionSummary .earnedBadgesSection {
  margin-top: 29px;
}

.SessionSummary .earnedBadgesSection .badge {
  background-color: rgb(59, 59, 189, 0.25);
  border-radius: 9px;
  height: 214px;
  margin-bottom: 25px;
  padding: 24px 10px;
  text-align: center;
}

.SessionSummary .earnedBadgesSection .AchievementBadge {
  background-color: transparent;
  border: 0;
  height: 100px;
  width: 100px;
}

.SessionSummary .earnedBadgesSection .badgeEarned,
.SessionSummary .earnedBadgesSection .badgeDescription {
  font-size: 20px;
  line-height: 24px;
  margin-top: 6px;
}

.SessionSummary .earnedBadgesSection .badgeEarned {
  color: #ffffff;
  font-weight: 600;
}

.SessionSummary .earnedBadgesSection .badgeDescription {
  color: #aca2cd;
  font-weight: normal;
}

.SessionSummary .earnedBadgesSection .badgeProgress {
  background-color: #000000;
  border-radius: 6.5px;
  display: inline-block;
  height: 11px;
  margin: 8px 0 7px;
  width: 78px;
}

.SessionSummary .earnedBadgesSection .badgeProgressValue {
  background-color: #00d5ae;
  border-radius: 6.5px;
  height: 100%;
}

.SessionSummary .LogoutSection {
  margin-top: 100px;
}

.SessionSummary .ProgressSection .numberCases {
  margin: -20px 0 30px;
}

.SessionSummary .ProgressSection .progressBarContainer {
  margin: 10px 42px 24px;
}

@media (max-width: 1365px) {
  .SessionSummary .pageHeader {
    margin-top: 100px;
    padding-left: 10px;
  }
}

@media (max-width: 1023px) {
  .SessionSummary .pageHeader {
    margin-bottom: 32px;
    margin-top: 60px;
    padding-left: 0;
  }

  .SessionSummary .sessionTotalValue {
    font-size: 80px;
    margin-top: 0;
  }

  .SessionSummary .ProgressSection .numberCases {
    margin-top: 10px;
  }

  .SessionSummary .ProgressSection .progressBarContainer {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 639px) {
  .SessionSummary .ProgressSection .numberCases {
    margin-top: 20px;
  }

  .SessionSummary .ProgressSection .value {
    font-size: 60px;
    line-height: 128px;
  }

  .SessionSummary .pageHeader {
    height: 70px;
  }

  .SessionSummary .pageHeader h1 {
    font-size: 28px;
    line-height: 70px;
  }

  .SessionSummary .controlButtons {
    text-align: right;
    width: 110px;
    white-space: normal;
  }

  .SessionSummary .controlButtons button {
    height: 32px;
    line-height: 32px;
    margin: 0;
  }

  .SessionSummary .controlButtons button.dashboard {
    margin-top: 10px;
    width: 110px;
  }

  .SessionSummary .controlButtons button.logout {
    line-height: 30px;
    margin-right: 8px;
    width: 110px;
  }
}

.ScreenshotQA .screenshot {
  width: 100%;
}

.ScreenshotQA .controlHeader {
  text-align: center;
}

.ScreenshotQA .controlHeader ul.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 0 25px 0;
}

.ScreenshotQA .controlHeader .pagination li {
  display: inline-block;
  padding: 5px 8px;
  border: 1px solid #a2a2eb;
  background-color: #eaeaff;
  cursor: pointer;
}

.ScreenshotQA .controlHeader .usernameField {
  display: inline-block;
}

.ScreenshotQA .controlHeader .usernameField label {
  font-size: 12px;
  display: block;
  text-align: left;
}

.ScreenshotQA .controlHeader .usernameField #username {
  display: block;
  height: 30px;
  font-size: 14px;
}

.ScreenshotQA .controlHeader .pagination .active {
  background-color: #b8b8f4;
}

.LeaderboardItem {
  align-items: center;
  border-bottom: solid 1px #58438d;
  display: flex;
  flex: 1 1;
  margin: 0 16px;
  padding: 0;
  width: calc(50% - 32px);
}

.LeaderboardItem .rank {
  align-items: center;
  background-color: #6c0ad6;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  font-size: 31px;
  font-weight: 600;
  justify-content: center;
  margin-right: 10px;
  min-height: 43px;
  min-width: 50px;
  text-align: center;
  width: 50px;
}

.LeaderboardItem .score {
  color: #7cf4fe;
  font-size: 43px;
  font-weight: 600;
  margin-right: 10px;
  min-width: 120px;
  width: 120px;
}

.LeaderboardItem .name {
  align-items: center;
  align-self: stretch;
  flex: 1 1;
  display: flex;
  overflow: hidden;
}

.LeaderboardItem .name > span:first-child {
  color: #ffffff;
  font-size: 26px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LeaderboardItem .name > span:last-child {
  flex-basis: 45%;
  min-width: 45%;
}

.LeaderboardItem .team {
  align-items: center;
  background-color: #2b195b;
  display: flex;
  height: 100%;
}

.LeaderboardItem .team span {
  color: #d4d1de;
  display: block;
  display: -webkit-box;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.15;
  max-height: 46px;
  opacity: 0.8;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}

.Leaderboard {
  overflow-y: scroll;
  padding: 5px 24px 50px;
}

.Leaderboard .Logo {
  font-size: 32px;
  margin: 20px 16px 0;
  white-space: nowrap;
}

.Leaderboard .section {
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
}

.Leaderboard .list {
  margin-top: 20px;
}

.Leaderboard .section h2 {
  color: #7cf4fe;
  display: flex;
  font-size: 36px;
  font-weight: normal;
  line-height: 50px;
  margin: 0;
  white-space: nowrap;
}

.Leaderboard .section h2 img {
  margin: 0 16px;
}

.Leaderboard .sectionIndividuals .LeaderboardItem {
  max-height: 66px;
  min-height: 66px;
}

.Leaderboard .sectionTeams .LeaderboardItem {
  max-height: 56px;
  min-height: 56px;
}

.Leaderboard .sectionIndividuals .LeaderboardItem:nth-child(n+21) {
  display: none;
}

.Leaderboard .sectionTeams .LeaderboardItem:nth-child(n+7) {
  display: none;
}

@media (min-width: 1366px) {
  .Leaderboard .sectionIndividuals .list {
    height: 660px;
  }

  .Leaderboard .sectionTeams .list {
    height: 168px;
  }

  .Leaderboard .list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
  }
}

@media (min-width: 1680px) {
  .Leaderboard .LeaderboardItem .name > span:first-child {
    flex-grow: 1;
  }
}

@media (max-width: 1679px) {
  .Leaderboard .LeaderboardItem .name {
    flex-direction: column;
    justify-content: center;
  }

  .Leaderboard .LeaderboardItem .name > span {
    width: 100%;
  }

  .Leaderboard .LeaderboardItem .name .team {
    background-color: transparent;
    height: auto;
  }

  .Leaderboard .LeaderboardItem .name .team span {
    display: block;
    opacity: 0.5;
    padding: 0;
    white-space: nowrap;
  }
}

@media (max-width: 1365px) {
  .Leaderboard .LeaderboardItem {
    width: calc(100% - 32px);
  }
}

@media (max-width: 639px) {
  .Leaderboard {
    padding: 5px 12px 20px;
  }

  .Leaderboard .Logo {
    font-size: 26px;
    margin: 10px 0 0;
  }

  .Leaderboard .section {
    margin-top: 10px;
    padding-top: 10px;
  }

  .Leaderboard .section h2 {
    border-bottom: 1px solid #7cf4fe;
    font-size: 24px;
  }

  .Leaderboard .section h2 img {
    margin: 0 10px;
    margin-right: 10px;
    max-width: 32px;
  }

  .Leaderboard .section .LeaderboardItem {
    border-bottom: 0;
    margin: 5px 0;
    max-height: 66px;
    min-height: 66px;
  }

  .Leaderboard .list {
    margin-top: 0;
  }

  .Leaderboard .LeaderboardItem {
    margin: 0;
    position: relative;
    width: 100%;
  }

  .Leaderboard .LeaderboardItem .rank {
    align-items: baseline;
    font-size: 20px;
    height: 56px;
    padding-top: 4px;
    width: 70px;
  }

  .Leaderboard .LeaderboardItem .rank::before {
    content: '#';
  }

  .Leaderboard .LeaderboardItem .score {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    bottom: 8px;
    box-shadow: inset 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    font-size: 18px;
    left: 4px;
    margin: 0;
    min-width: unset;
    position: absolute;
    text-align: center;
    width: 62px;
  }

  .Leaderboard .LeaderboardItem .name > span:first-child {
    flex-grow: 0;
    font-size: 18px;
  }

  .Leaderboard .LeaderboardItem .name .team span {
    font-size: 16px;

    display: -webkit-box;
    padding: 0;
    white-space: unset;
  }
}

.Leaderboard.fitScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 20px;
  width: 100%;
}

.Leaderboard.fitScreen .Logo {
  display: none;
}

.Leaderboard.fitScreen .section {
  margin-top: 0;
}

.Leaderboard.fitScreen .list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  height: calc(100% - 80px);
  left: 0;
  margin-top: 0;
  overflow: hidden;
  position: absolute;
  top: 80px;
  width: 100%;
}

.Leaderboard.fitScreen .sectionIndividuals {
  flex-basis: 75%;
}

.Leaderboard.fitScreen .sectionTeams {
  flex-basis: 25%;
}

.Leaderboard.fitScreen .sectionIndividuals .LeaderboardItem:nth-child(n+21) {
  display: flex;
}

.Leaderboard.fitScreen .sectionTeams .LeaderboardItem:nth-child(n+7) {
  display: flex;
}

@media (max-height: 600px) {
  .Leaderboard.fitScreen .sectionTeams {
    display: none;
  }

  .Leaderboard.fitScreen .sectionIndividuals {
    flex-basis: 100%;
  }
}


